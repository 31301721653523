const sizes = {
  desktop: '875px',
  tablet: '725px',
  mobileNav: '515px',
  phone: '470px',
  smaller: '360px'

}

const media = {
  desktop: `@media (min-width: ${sizes.desktop})`,
  minTablet: `@media (min-width: ${sizes.tablet})`,
  tablet: `@media (max-width: ${sizes.tablet})`,
  mobileNav: `@media (max-width: ${sizes.mobileNav})`,
  phone: `@media (max-width: ${sizes.phone})`,
  smaller: `@media (max-width: ${sizes.smaller})`,
  print: `@media print`
}

export {
  media
}
