import styled from 'styled-components'

export const SpinnerContainer = styled.div`
    padding: ${props => (props.withoutPadding ? '0' : '1rem')};
    display: flex;
    margin: ${props => (props.space ? '2.5rem auto 0' : '0')};
    width: 100%;
    justify-content: center;
    align-items: center;

    div {
        border-width: 5px;
    }
`
