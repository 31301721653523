import styled from 'styled-components'

export const AvatarWrapper = styled.div`
    width: 100%
`

export const DeleteWrapper = styled.div`
    position: relative;
    display: flex;
    margin-top: 10px;
`

export const CheckboxWrapper = styled.div`
    display: flex;
    
    span {
        margin-top: 0 !important;

        label {
            margin-top: 0 !important;
        }
    }
    svg {
        margin-top: 0 !important;
    }
`
