import { DropDown, DropDownContent, DropDownItem, IconWrapper } from './index.styles'

import Icon from '@mdi/react'
import React from 'react'

const DropDownMenu = ({ iconPath, iconColor, menuActions, ...props }) => {
  const menuClick = (action) => (evt) => {
    evt.stopPropagation()
    action()
  }

  return (
    <DropDown>
      <IconWrapper><Icon color={iconColor} path={iconPath} size={1} /></IconWrapper>
      <DropDownContent>
        {menuActions.map((x, index) => <DropDownItem key={index} onClick={menuClick(x.action)}>{x.title}</DropDownItem>)}
      </DropDownContent>
    </DropDown>
  )
}

export default DropDownMenu
