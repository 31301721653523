import React from 'react'
import InactiveLayout from 'shared/components/InactiveLayout'
import { Button } from 'shared/components/Button'
import { mdiFaceAgent } from '@mdi/js'
import { useModalContext } from 'shared/context/ModalProvider'
import { colors } from 'shared/colors'
import HelpModal from 'shared/components/HelpModal'
import UpdateBillingButton from 'shared/components/UpdateBillingButton'

function InactiveFreeTrial (props) {
  const { openModal } = useModalContext()

  return (
    <InactiveLayout caption="Let’s fix that. Sign up for a plan or contact our support to regain access to your notes and start using Mobile Assistant again!" title="Oh no! It looks like your free trial has expired." {...props}>
      <UpdateBillingButton />
      <Button iconColor={colors.BLACK} iconName={mdiFaceAgent} onClick={() => openModal(<HelpModal/>)} inverse>Need Help?</Button>
    </InactiveLayout>
  )
}

export default InactiveFreeTrial
