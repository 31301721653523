import { GroupTypeDetail, MenuIconWrapper, Section, TitleContainer } from './index.styles'
import { canEditTemplate, canShareToEveryone, canShareToOrg, canShareToTeam } from '../shared/assistantLogic'
import { mdiAccountMultiple, mdiCheckDecagram, mdiDomain, mdiDotsHorizontalCircle } from '@mdi/js'

import DropDownMenu from 'shared/components/DropDownMenu'
import { H3 } from 'shared/components/Text'
import Icon from '@mdi/react'
import React from 'react'
import { colors } from 'shared/colors'

function TemplateListItem ({ template, color, onClick, onMenu, user, ...props }) {
  const menuActions = () => {
    const actions = []
    actions.push({
      title: 'View',
      action: () => onMenu(template, 'View')
    })
    if (canEditTemplate(template, user)) {
      actions.push({
        title: 'Edit',
        action: () => onMenu(template, 'Edit')
      })
    }
    if (canShareToTeam(template, user) && !template.group_id) {
      actions.push({
        title: 'Share to Team',
        action: () => onMenu(template, 'ShareTeam')
      })
    }
    if (canShareToOrg(template, user) && !template.group_id) {
      actions.push({
        title: 'Share to Organization',
        action: () => onMenu(template, 'ShareOrg')
      })
    }
    if (canShareToEveryone(template, user) && !template.group_id) {
      actions.push({
        title: 'Share to All Users ',
        action: () => onMenu(template, 'ShareEveryone')
      })
    }
    const talkItUser = user.talkItAttributes.user
    if (template.user_id === talkItUser.id && template.group_id) {
      actions.push({
        title: 'Stop Sharing',
        action: () => onMenu(template, 'StopShare')
      })
    }
    return actions
  }

  const getGroupTypeDetails = (template, user) => {
    const talkItUser = user.talkItAttributes.user
    if (template.group_id === talkItUser?.group?.team_group_id) {
      return {
        icon: mdiAccountMultiple,
        name: 'Team'
      }
    }
    if (template.group_id === talkItUser?.group?.org_group_id) {
      return {
        icon: mdiDomain,
        name: 'Organization'
      }
    }
    if (template.group_id === 1) {
      return {
        icon: mdiCheckDecagram,
        name: 'Default'
      }
    }
    return {
      icon: mdiAccountMultiple,
      name: 'Shared'
    }
  }

  return (
    <Section
      color={template.color}
      filled={!template.isAddTemplate}
      onClick={onClick}>
      <MenuIconWrapper>
        {!template.isAddTemplate && canEditTemplate(template, user) && <DropDownMenu iconColor={colors.WHITE} iconPath={mdiDotsHorizontalCircle} menuActions={menuActions()}></DropDownMenu>}
      </MenuIconWrapper>
      <TitleContainer>
        <H3 style={{ marginBottom: 0, color }}>{template.title}</H3>
        {template.group_id && <GroupTypeDetail>
          <Icon color={color} path={getGroupTypeDetails(template, user).icon} size={0.75} style={{ marginRight: '0.25rem' }} /> {getGroupTypeDetails(template, user).name}
        </GroupTypeDetail>}
      </TitleContainer>
    </Section>
  )
}

export default TemplateListItem
