import { Button, NavLinkButton } from '../Button/index'
import { NavItem, SidebarWrapper, TalkItTitle } from './index.styles'
import React, { useState } from 'react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

import { Link } from '../Text'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import { SIDEBAR_NAVIGATION_CONFIG } from 'shared/navigation_configs'
import { colors } from 'shared/colors'

function Sidebar (props) {
  const [navOpen, setNavOpen] = useState(true)

  const handleClick = () => {
    setNavOpen(!navOpen)
  }

  const navClass = navOpen ? 'ExpandedNav' : 'MinimizedNav'
  const navItemClass = navOpen ? ['expandedButton'] : ['iconButton']
  const collapseControlClass = navOpen ? ['expandedButton'] : ['iconButton', 'minimizeButton']
  const collapseControlIcon = navOpen ? mdiChevronLeft : mdiChevronRight

  return (
    <SidebarWrapper {...props}>
      <nav className={navClass}>
        <TalkItTitle className="talkItTitle">
          <Link to="/">
            <Logo alt="Mobile Assistant Logo"/>
            {navOpen && <h1>Talk It<span className="online">Online</span></h1>}
          </Link>
        </TalkItTitle>
        <ul>
          {SIDEBAR_NAVIGATION_CONFIG.map((item, index) => (
            <NavItem key={index} title={item.title}>
              <NavLinkButton className={navItemClass} data-testid={item.title} iconColor={colors.BLACK} iconName={item.icon} iconSize={1.25} to={item.to} text>{navOpen && item.title}</NavLinkButton>
            </NavItem>
          ))}
        </ul>
        <Button className={['toggleButton', ...collapseControlClass]} data-testid="collapseControl" iconColor={colors.BLACK} iconName={collapseControlIcon} onClick={handleClick} text>
          {navOpen && 'Minimize'}
        </Button>
      </nav>
    </SidebarWrapper>
  )
}

export default Sidebar
