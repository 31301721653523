import {
  EditContainer,
  Question,
  QuestionContainer,
  TemplateWrapper,
  Title,
  TitleWrapper
} from './index.styles'

import React from 'react'
import { canEditTemplate } from '../../assistantLogic'
import Modal from 'shared/components/Modal'

function ViewTemplate ({ template, user, onBack, onEdit }) {
  const getModalDialogue = () => {
    if (canEditTemplate(template, user)) {
      return {
        primary: {
          onClick: () => onEdit(template),
          children: 'Edit Template'
        }
      }
    }
    return null
  }

  const modalDialogue = getModalDialogue()

  return (
    <Modal dialogue={modalDialogue} onClose={onBack} title="Template">
      <EditContainer>
        <TemplateWrapper>
          <div>Title</div>
          <TitleWrapper>
            <Title>{template.title}</Title>
          </TitleWrapper>
          <div>Questions</div>
          {template.questions.map((item, index) => (
            <QuestionContainer
              color={template.color}
              key={index}
            >
              <Question key={index} name={index}>{item}</Question>
            </QuestionContainer>
          ))}
        </TemplateWrapper>
      </EditContainer>
    </Modal>
  )
}

export default ViewTemplate
