import React from 'react'
import AccountTabs from '../AccountTabs'
import { H1 } from '../Text'
import MobileTabNav from '../MobileTabs'
import { Area, Header, Nav, Container, FloatingActions } from './index.styles'
import { Button } from '../Button'
import { mdiFaceAgent } from '@mdi/js'
import HelpModal from '../HelpModal'
import Banner from '../Banner'
import { useModalContext } from 'shared/context/ModalProvider'
import { useAuthContext } from 'shared/context/AuthProvider'
import { getIsUserAdmin } from 'shared/extractors'
import { colors } from 'shared/colors'

function AccountPageLayout (props) {
  const { openModal } = useModalContext()
  const { currentUser } = useAuthContext()
  const isNotAdmin = !getIsUserAdmin(currentUser)

  return (
    <Area>
      {isNotAdmin && <Banner title="Your account is managed by your team's admin" inverse />}
      <Header>
        <H1>Account</H1>
        <Nav>
          <AccountTabs />
          <MobileTabNav />
        </Nav>
      </Header>
      <Container>
        {props.children}
      </Container>
      <FloatingActions>
        <Button iconColor={colors.WHITE} iconName={mdiFaceAgent} onClick={() => openModal(<HelpModal/>)} fab>Need Help?</Button>
      </FloatingActions>
    </Area>
  )
}

export default AccountPageLayout
