import React from 'react'
import { useAuthContext } from 'shared/context/AuthProvider'
import InactiveFreeTrial from 'shared/components/InactiveFreeTrial'
import InactiveUnpaid from 'shared/components/InactiveUnpaid'
import { getTalkItUser } from 'shared/extractors'

function AccountInactive (props) {
  const { currentUser } = useAuthContext()
  const { isInactive, isTrialUser } = getTalkItUser(currentUser)

  if (isInactive && isTrialUser) {
    return <InactiveFreeTrial data-testid="inactive-trial" />
  }

  return <InactiveUnpaid data-testid="inactive-unpaid" />
}

export default AccountInactive
