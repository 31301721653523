import React from 'react'
import { mdiInformationOutline } from '@mdi/js'
import { P2 } from '../Text'
import { colors } from 'shared/colors'
import Icon from '@mdi/react'
import { PopupStyle, PopupIcon } from './index.styles'

const InfoPopup = ({ ...props }) => {
  return (
    <PopupStyle {...props}>
      {props.label ? <P2>{props.label}</P2> : null}
      <PopupIcon {...props}>
        <Icon color={colors.BLUE}
          data-testid="icon"
          path={mdiInformationOutline}
          size={1}/>
      </PopupIcon>
    </PopupStyle>
  )
}

export default InfoPopup
