import { Auth } from 'aws-amplify'

const constants = Object.freeze({
  AWS: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_fyTd6mVO9',
      userPoolWebClientId: 'vom7ra3pkmv1nmrlhcq9lpvuj',
      identityPoolId: 'us-east-1:aabee669-c9de-4fb8-88b5-00a86299dc82',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'TALK_IT_API',
          endpoint: 'https://dev-api.mobileassistant.us'
        },
        {
          name: 'TALK_IT_API_ADMIN',
          endpoint: 'https://dev-api.mobileassistant.us',
          custom_header: async (h) => {
            return { AuthToken: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        }
      ]
    }
  },
  TALK_IT_API: {
    HOST: 'https://dev-api.mobileassistant.us'
  },
  SENTRY: {
    DSN: 'https://b06c4d2911a248d8929aa315bae1e109@sentry.io/1406883'
  },
  AMPLITUDE: {
    API_KEY: 'a27826d0758bb0ced212d8298df6b87b'
  },
  GOOGLE_ANALYTICS_API_KEY: '',
  RECAPTCHA_API_KEY: '6Lex0LYZAAAAAM78-7PVNw-e1hken0ezU3a0f0e6',
  THANKYOU_REDIRECT_URL: 'https://mobileassistant.us/free-trial-thank-you/',
  TIAA_IDS: [15550, 15575, 15739, 16835, 16953, 17802, 19080, 19158, 20481, 22581, 24838]
})

export default constants
