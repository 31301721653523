import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const ActiveNote = styled.div`
    position: absolute;
    width: .25rem;
    height: 100%;
    border-radius: 0 .25rem .25rem 0;
    transform: translate(-.75rem, -.75rem);
    background: ${colors.BLUE};
`

export const StyledNote = styled.div`
    position: relative;
    width: 100%;
    padding: .75rem;
    display: flex;
    flex-flow: column;
    align-items: top;
    justify-content: flex-start;
    transition: all .25s ease;
    :hover {
        cursor: pointer;
        background: ${colors.GREY_100};
    }
    :active {
        opacity: .5;
    }
    ${props => props.selected && css`
        background: ${colors.GREY_100};
    `}

    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        background: ${colors.GREY_100};
        width: 100%;
        left: 0;
        height: 2px;
        z-index: 1;
    }
`

export const Subject = styled.div`
    text-transform: capitalize;
    color: ${colors.BLACK};
    display: flex;
    margin-bottom: .2rem;
    min-height: 1.5rem;

    h4 {
        display: flex;
        align-self: center;
    }

    svg {
        margin-left: auto;
    }
`

export const DateLineContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`

export const Date = styled.span`
    color: ${colors.BLACK}
    opacity: .5;
`
