import styled, { css } from 'styled-components'

export const Frame = styled.iframe`
    display: none;

    ${props => props.display && css`
        display: block;
        width: calc(100% + 7rem);
        min-height: 800px;
        margin: 0 -3.5rem;
    `}
`

export const PaymentContainer = styled.div`
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
`
