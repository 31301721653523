import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthProvider from '../shared/context/AuthProvider'
import Layout from '../shared/components/Layout'
import { GlobalStyle } from './index.styles'
import NotificationProvider, { NotificationContainer } from '../shared/context/NotificationProvider'
import ModalProvider, { ModalContainer } from '../shared/context/ModalProvider'

function App () {
  return (
    <Router>
      <GlobalStyle />
      <AuthProvider>
        <ModalProvider>
          <NotificationProvider>
            <Layout />
            <ModalContainer />
            <NotificationContainer />
          </NotificationProvider>
        </ModalProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
