import { Redirect, Route, Switch } from 'react-router-dom'
import AccountBilling from 'account/AccountBilling'
import AccountInactive from 'account/AccountInactive'
import AccountIntegrations from 'account/AccountIntegrations'
import AccountNotifications from 'account/AccountNotifications'
import AccountOverview from 'account/AccountOverview'
import AccountPassthrough from 'account/AccountPassthrough'
import AccountSubscriptions from 'account/AccountSubscriptions'
import AssistantOverview from 'assistant/AssistantOverview'
import ForgotPassword from 'account/ForgotPassword'
import ListNotes from 'notes/ListNotes'
import React from 'react'
import SetPassword from 'account/SetPassword'
import SignIn from 'account/SignIn'
import SignUp from 'account/SignUp'
import ThankYou from 'account/ThankYou'
import events from './events'
import { getTalkItUser } from './extractors'
import { log } from './logger'
import { useAuthContext } from './context/AuthProvider'

const PUBLIC_ROUTES_CONFIG = [
  { path: '/', exact: true, component: (props) => <Redirect to={{ pathname: '/signin', search: props.location.search }} /> },
  { path: '/signup', component: SignUp, event: events.NAVIGATED_TO_SIGN_UP },
  { path: '/signin', component: SignIn },
  { path: '/thankyou', component: ThankYou },
  { path: '/forgotpassword', component: ForgotPassword, event: events.NAVIGATED_TO_FORGOT_PASSWORD },
  { path: '/setpassword', component: SetPassword },
  { path: '/passthrough', component: (props) => <AccountPassthrough isLoggedIn={false} {...props} /> }
]

const STANDARD_PRIVATE_ROUTES_CONFIG = [
  { path: '/', exact: true, component: () => <Redirect to="/notes" />, event: events.NAVIGATED_TO_NOTES },
  { path: '/notes', component: ListNotes, event: events.NAVIGATED_TO_NOTES },
  { path: '/assistant', exact: true, component: () => <Redirect to="/assistant/overview" /> },
  { path: '/assistant/overview', component: AssistantOverview, event: events.NAVIGATED_TO_ASSISTANT },
  { path: '/account', exact: true, component: () => <Redirect to="/account/overview" /> },
  { path: '/account/overview', component: AccountOverview, event: events.NAVIGATED_TO_ACCOUNT },
  { path: '/account/notifications', component: AccountNotifications, event: events.NAVIGATED_TO_ACCOUNT_NOTIFICATIONS },
  { path: '/account/integrations', component: AccountIntegrations, event: events.NAVIGATED_TO_ACCOUNT_INTEGRATIONS },
  { path: '/account/inactive', component: AccountSubscriptions },
  { path: '/setpassword', component: SetPassword },
  { path: '/passthrough', component: (props) => <AccountPassthrough isLoggedIn={true} {...props} /> }
]

const ADMIN_PRIVATE_ROUTES_CONFIG = [
  ...STANDARD_PRIVATE_ROUTES_CONFIG,
  { path: '/account/billing', exact: true, component: AccountBilling, event: events.NAVIGATED_TO_ACCOUNT_BILLING },
  { path: '/account/billing/upgrade', component: AccountSubscriptions }
]

const STANDARD_INACTIVE_PRIVATE_ROUTES_CONFIG = [
  { path: '/', exact: true, component: () => <Redirect to="/account/inactive" /> },
  { path: '/account/inactive', component: AccountInactive },
  { path: '/setpassword', component: SetPassword }
]

const ADMIN_INACTIVE_PRIVATE_ROUTES_CONFIG = [
  ...STANDARD_INACTIVE_PRIVATE_ROUTES_CONFIG,
  { path: '/account/billing/upgrade', component: AccountSubscriptions }
]

function RouteWithSubRoutes (route) {
  if (route.event) {
    log(route.event)
  }

  return (
    <Route
      component={route.component}
      exact={route.exact}
      path={route.path}
    />
  )
}

function PublicRoutesForApp () {
  const routes = getRoutesWith404(PUBLIC_ROUTES_CONFIG)

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  )
}

function PrivateRoutesForApp () {
  const { currentUser } = useAuthContext()
  const { isAdmin, isInactive } = getTalkItUser(currentUser)
  const routes = getPrivateRoutes({ isAdmin, isInactive })

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  )
}

function getPrivateRoutes ({ isAdmin, isInactive }) {
  if (isAdmin && isInactive) {
    return getRoutesWith404(ADMIN_INACTIVE_PRIVATE_ROUTES_CONFIG)
  }
  if (isAdmin && !isInactive) {
    return getRoutesWith404(ADMIN_PRIVATE_ROUTES_CONFIG)
  }
  if (!isAdmin && isInactive) {
    return getRoutesWith404(STANDARD_INACTIVE_PRIVATE_ROUTES_CONFIG)
  }
  return getRoutesWith404(STANDARD_PRIVATE_ROUTES_CONFIG)
}

function getRoutesWith404 (routes) {
  return [...routes, { component: () => <Redirect to="/" /> }]
}

export {
  PrivateRoutesForApp,
  PublicRoutesForApp
}
