import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useModalContext } from 'shared/context/ModalProvider'
import { Frame, PaymentContainer } from './index.styles'
import Modal from '../Modal'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import { useAuthContext } from 'shared/context/AuthProvider'
import { API } from 'aws-amplify'
import { getTalkItUser, getQueryStringAsObject } from 'shared/extractors'
import LoadingSpinner from '../LoadingSpinner'
import { text } from 'shared/text'

const PaymentProfileModal = (props) => {
  const { currentUser, refreshUser } = useAuthContext()
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [paymentSource, setPaymentSource] = useState({})
  const [loading, setLoading] = useState(true)
  const [iframeLoading, setIframeLoading] = useState(true)
  const { cognitoId, teamId } = getTalkItUser(currentUser)
  const paymentFormRef = useRef(null)
  const isAddingPaymentSource = paymentSource.payment_source_id === '0'

  const fetchPaymentSource = useCallback(async () => {
    setLoading(true)
    try {
      const results = (await API.get('TALK_IT_API', `/team/${teamId}/payment_source/authorize_net?cognito_id=${cognitoId}`)).data
      setPaymentSource(results)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [cognitoId, teamId])

  const updateTeam = async () => {
    setLoading(true)
    setIframeLoading(true)
    try {
      await API.put('TALK_IT_API', `/team/${teamId}?cognito_id=${cognitoId}`, {
        body: getUpdateTeamPayload(isAddingPaymentSource)
      })
      await refreshUser()
      setLoading(false)
      triggerSuccess(isAddingPaymentSource ? text.PAYMENT.CREATE.SUCCESS : text.PAYMENT.UPDATE.SUCCESS)
    } catch (error) {
      triggerFailure(isAddingPaymentSource ? text.PAYMENT.CREATE.FAILED : text.PAYMENT.UPDATE.FAILED)
    }
  }

  const getUpdateTeamPayload = (isAddingPaymentSource) => {
    if (isAddingPaymentSource) {
      return {
        payment_source_status: 'pending',
        subscription_interval: props.subscriptionInterval,
        subscription_id: props.subscriptionId,
        promo_code: props.promoCode,
        is_new_subscription: true
      }
    }

    return {
      payment_source_status: 'pending'
    }
  }

  useEffect(() => {
    fetchPaymentSource()
  }, [fetchPaymentSource])

  useEffect(() => {
    if (!loading && iframeLoading && paymentFormRef && paymentFormRef.current && paymentFormRef.current.submit) {
      paymentFormRef.current.submit()
      setIsSubmitted(true)
    }
  }, [paymentFormRef, loading, iframeLoading])

  const triggerSuccess = (title) => {
    sendSuccessNotification({ title })
    closeModal()
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const setIframeLoaded = () => {
    if (isSubmitted && iframeLoading) {
      setIframeLoading(false)
    }
  }

  window.CommunicationHandler = {}
  window.CommunicationHandler.onReceiveCommunication = (payload) => {
    if (payload.qstr) {
      const params = getQueryStringAsObject(payload.qstr)
      if (params.action === 'cancel') {
        closeModal()
      }
      if (params.action === 'successfulSave') {
        updateTeam()
      }
    }
  }

  if (loading) {
    return (
      <Modal onClose={closeModal} title="Payment Profile" {...props}>
        <PaymentContainer>
          <LoadingSpinner size={40} />
        </PaymentContainer>
      </Modal>
    )
  }

  return (
    <Modal onClose={closeModal} title="Payment Profile" {...props}>
      <PaymentContainer>
        {iframeLoading && <LoadingSpinner size={40} />}
        <Frame display={iframeLoading ? undefined : 'show'} frameBorder="none" id="paymentProfile" name="paymentProfile" onLoad={setIframeLoaded} title="Payment Profile"/>
        <form action={paymentSource.payment_source_url} method="post" ref={paymentFormRef} target="paymentProfile">
          <input name="token" type="hidden" value={paymentSource.customer_token} />
          {!isAddingPaymentSource && <input name="paymentProfileId" type="hidden" value={paymentSource.payment_source_id} />}
        </form>
      </PaymentContainer>
    </Modal>
  )
}

export default PaymentProfileModal
