import React from 'react'
import { StyledNote, Subject, Date, ActiveNote, DateLineContainer } from './index.styles'
import { H4, Caption } from 'shared/components/Text'
import Icon from '@mdi/react'
import { colors } from 'shared/colors'
import { mdiClockOutline } from '@mdi/js'
import { formatStandardDate } from 'shared/formatters'

function Note (props) {
  return (
    <StyledNote {...props}>
      {props.selected && <ActiveNote/>}
      <Subject><H4 noSpace>{props.subject || props.status}</H4>{props.status === 'pending' && <Icon color={colors.BLUE} path={mdiClockOutline} size={1}></Icon>}</Subject>
      <DateLineContainer>
        <Caption noSpace>
          <Date>{formatStandardDate(props.created_on)}</Date>
        </Caption>
      </DateLineContainer>
    </StyledNote>
  )
}

export default Note
