import styled from 'styled-components'
import { colors } from 'shared/colors'

export const PopupStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;

    p {
        margin-left: .25rem;
        WHITE-space: nowrap;
    }
`

export const PopupIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: all .25s ease;

    :after {
        transition: all .25s ease;
        content: '${props => props.message}';
        padding: .875rem;
        left: 0;
        top: 24px;
        background: ${colors.BLACK};
        border-radius: .9rem;
        color: ${colors.WHITE};
        position: absolute;
        max-width: 350px;
        box-shadow: 0 0 0 ${colors.TRANSPARENT};
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        font-family: 'Nunito Sans',sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: .825rem;
        line-height: 1.2rem;
    }

    :hover {
        background: ${colors.BLACK};
        cursor: pointer;
        :after {
            left: 0;
            top: 30px;
            opacity: 1;
            visibility: visible;
            box-shadow: 0 .25rem 1rem ${colors.BLACK_A20};
        }
    }

`
