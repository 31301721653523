import React from 'react'
import Icon from '@mdi/react'
import { H4, Caption } from '../Text'
import { LabelWrapper, Container, RadioIcon } from './index.styles'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import { colors } from 'shared/colors'

const RadioButton = ({ label, children, isSelected, name, onSelected, ...props }) => {
  const onClickHandler = () => {
    if (onSelected) onSelected(name)
  }

  return (
    <Container onClick={onClickHandler}>
      <LabelWrapper>
        <RadioIcon>
          {isSelected
            ? <Icon data-testid='rb_selected' {...props} className="mainIcon" color={colors.BLUE} path={mdiRadioboxMarked} size={1}></Icon>
            : <Icon data-testid='rb_unselected' {...props} className="mainIcon" color={colors.BLUE} path={mdiRadioboxBlank} size={1}></Icon>
          }
        </RadioIcon>
        <H4 noSpace>{label}</H4>
      </LabelWrapper>
      <Caption>{children}</Caption>
    </Container>
  )
}

export default RadioButton
