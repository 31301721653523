import React from 'react'
import { ItemRow, PaidStatusColumn, UnpaidStatusColumn, DateColumn, AmountColumn, ViewColumn, ViewButton, QuantityColumn } from './index.styles'
import { mdiOpenInNew } from '@mdi/js'
import Icon from '@mdi/react'
import env from 'shared/envConfig'
import { formatStandardDate } from 'shared/formatters'
import { colors } from 'shared/colors'

const InvoiceItem = ({ logItem = {}, teamId, cognitoId, showQuantity }) => {
  const formatCurrency = (number = 0) => {
    const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })
    return currency.format(number)
  }

  return (
    <ItemRow>
      {logItem.IsPaid ? (<PaidStatusColumn>Paid</PaidStatusColumn>) : (<UnpaidStatusColumn>Unpaid</UnpaidStatusColumn>)}
      <DateColumn>{formatStandardDate(logItem.InvoiceDate)}</DateColumn>
      {showQuantity ? (<QuantityColumn>{logItem.Seats}</QuantityColumn>) : (null)}
      <AmountColumn>{formatCurrency(logItem.InvoiceAmount)}</AmountColumn>
      <ViewColumn>{logItem.HasHtml ? (<ViewButton href={`${env.TALK_IT_API.HOST}/team/${teamId}/invoice/${logItem.PkMAInvoiceLog}?cognito_id=${cognitoId}`} rel="noopener noreferrer" target="_blank"><Icon color={colors.BLACK} path={mdiOpenInNew} size="20px"></Icon></ViewButton>) : (null)}</ViewColumn>
    </ItemRow>
  )
}

export default InvoiceItem
