import React, { useState, useEffect, useCallback } from 'react'
import { StyledLayout, BackButton, Backdrop } from './index.styles'
import { API } from 'aws-amplify'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import SubscriptionPicker from 'shared/components/SubscriptionPicker'
import { Button } from 'shared/components/Button'
import { colors } from 'shared/colors'
import { mdiArrowLeft } from '@mdi/js'

function AccountSubscriptions (props) {
  const [subscriptions, setSubscriptions] = useState()
  const [upgradeButtonText, setUpgradeButtonText] = useState()
  const [loading, setLoading] = useState(true)

  const fetchSubscriptions = useCallback(async () => {
    setLoading(true)
    try {
      const subscriptionResults = (await API.get('TALK_IT_API', `/subscription`)).data
      const upgradeButtonResults = (await API.get('TALK_IT_API', `/text_assets?asset_id=upgrade_button_text`)).data
      setSubscriptions(subscriptionResults)
      setUpgradeButtonText(upgradeButtonResults.text_asset_value)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const redirectCheck = async () => {
      try {
        const redirectFlag = (await API.get('TALK_IT_API', `/flags?flag_id=redirect_upgrade_20221003`)).data
        if (redirectFlag.flag_value) {
          window.location.replace(`${redirectFlag.asset_value}`)
        } else {
          fetchSubscriptions()
        }
      } catch (error) {
        fetchSubscriptions()
      }
    }
    redirectCheck()
  }, [fetchSubscriptions])

  if (loading) {
    return (
      <Backdrop>
        <StyledLayout data-testid="loading-layout" center>
          <LoadingSpinner backColor={colors.BLUE} frontColor={colors.BLUE_200} size={60} />
        </StyledLayout>
      </Backdrop>
    )
  }

  return (
    <Backdrop>
      <BackButton>
        <Button iconColor={colors.BLACK} iconName={mdiArrowLeft} onClick={() => props.history.goBack()} text>Back</Button>
      </BackButton>
      <SubscriptionPicker subscriptions={subscriptions} upgradeButtonText={upgradeButtonText} />
    </Backdrop>
  )
}

export default AccountSubscriptions
