const constants = Object.freeze({
  AWS: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_fyTd6mVO9',
      userPoolWebClientId: 'vom7ra3pkmv1nmrlhcq9lpvuj',
      identityPoolId: 'us-east-1:aabee669-c9de-4fb8-88b5-00a86299dc82',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'TALK_IT_API',
          endpoint: 'https://dev-api.mobileassistant.us'
        }
      ]
    }
  },
  TALK_IT_API: {
    HOST: 'https://dev-api.mobileassistant.us'
  },
  SENTRY: {
    DSN: ''
  },
  AMPLITUDE: {
    API_KEY: ''
  },
  GOOGLE_ANALYTICS_API_KEY: '',
  RECAPTCHA_API_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  THANKYOU_REDIRECT_URL: '',
  TIAA_IDS: [15550, 15575, 15739, 16835, 16953, 17802, 19080, 19158, 20481, 22581, 24838]
})

export default constants
