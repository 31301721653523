import { API, Auth } from 'aws-amplify'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { logUserSignedIn, logUserSignedOut } from 'shared/logger'

import { getCognitoId } from 'shared/extractors'

export const AuthContext = createContext(null)

const AuthProvider = props => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        if (currentUser) {
          await signIn(currentUser)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchCurrentUser()
  }, [])

  const signIn = async (currentUser) => {
    const { talkItUser, talkItTeam } = await fetchUser(currentUser)
    setCurrentUser({ ...currentUser, talkItAttributes: { user: talkItUser, team: talkItTeam } })
    logUserSignedIn(talkItUser)
  }

  const signOut = async () => {
    await Auth.signOut()
    setCurrentUser(null)
    logUserSignedOut()
  }

  const refreshUser = async () => {
    const { talkItUser, talkItTeam } = await fetchUser(currentUser)
    setCurrentUser({ ...currentUser, talkItAttributes: { user: talkItUser, team: talkItTeam } })
  }

  const isCurrentUserValid = () => {
    return currentUser && currentUser.attributes && currentUser.username === currentUser.attributes.email
  }

  const reloadUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser()
    if (isCurrentUserValid) {
      await signIn(currentUser)
    } else {
      await signOut()
    }
  }

  return <AuthContext.Provider value={{ loading, currentUser, signIn, signOut, refreshUser, reloadUser }} {...props} />
}

export const useAuthContext = () => useContext(AuthContext)

async function fetchUser (currentUser) {
  const cognitoId = getCognitoId(currentUser)
  const talkItUser = (await API.get('TALK_IT_API', `/user/${cognitoId}`)).data
  const { team_id: teamId } = talkItUser
  const talkItTeam = (await API.get('TALK_IT_API', `/team/${teamId}?cognito_id=${cognitoId}`)).data
  return { talkItUser, talkItTeam }
}

export default AuthProvider
