import { ActiveBar, TabBorder, TabList, TabsContainer } from './index.styles.js'

import React from 'react'
import { Tab } from '../Text/'
import { getAccountRoutes } from 'shared/navigation_configs'
import { getIsUserAdmin } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'

function AccountTabs (props) {
  const { currentUser } = useAuthContext()
  const ACCOUNT_ROUTES_CONFIG = getAccountRoutes(getIsUserAdmin(currentUser))

  const isActive = (path) => {
    return (path === window.location.pathname)
  }

  return (
    <TabsContainer {...props}>
      <TabList>
        {ACCOUNT_ROUTES_CONFIG.map((item, index) => (
          <li key={index}>
            <Tab active={isActive(item.to)} to={item.to} text>{item.title}{isActive(item.to) ? <ActiveBar data-testid='active-bar' /> : <div/>}</Tab>
          </li>
        ))}
      </TabList>
      <TabBorder/>
    </TabsContainer>
  )
}

export default AccountTabs
