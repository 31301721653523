import React, { useState, useEffect, useCallback } from 'react'
import { API } from 'aws-amplify'
import { Section, Column, SubSection, RecipientList, RecipientClickArea, EmptyState } from './index.styles'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { getInitialsFromName, getTalkItUser } from 'shared/extractors'
import { H3, Caption } from 'shared/components/Text'
import { text } from 'shared/text'
import InfoPopup from 'shared/components/InfoPopup'
import RadioButton from 'shared/components/RadioButton'
import Checkbox from 'shared/components/Checkbox'
import Avatar from 'shared/components/Avatar'
import { Button } from 'shared/components/Button'
import Container from 'shared/components/Container'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import ListItem from 'shared/components/ListItem'
import RegisterContactModal from 'shared/components/RegisterContactModal'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import AccountPageLayout from 'shared/components/AccountPageLayout'
import { NOTIFICATION_TYPE } from 'shared/enums'

function AccountNotifications () {
  const { openModal } = useModalContext()
  const [loading, setLoading] = useState(false)
  const [defaultRecipients, setDefaultRecipients] = useState([])
  const [optionalRecipients, setOptionalRecipients] = useState([])
  const { currentUser, refreshUser } = useAuthContext()
  const { cognitoId, userPreferences } = getTalkItUser(currentUser)
  const [notificationType, setNotificationType] = useState(userPreferences.notification_type)
  const { sendErrorNotification } = useNotificationContext()

  const fetchData = useCallback(async () => {
    setLoading(true)
    const recipients = (await fetchRecipients(cognitoId))
    setDefaultRecipients(recipients.filter(isDefault))
    setOptionalRecipients(recipients.filter(isNotDefault))
    setLoading(false)
  }, [cognitoId])

  const fetchRecipients = async (cognitoId) => {
    if (!cognitoId) {
      return []
    }

    try {
      const emailList = (await API.get('TALK_IT_API', `/user_email?cognito_id=${cognitoId}`)).data
      return emailList
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const isDefault = (value) => {
    return value.is_default
  }

  const isNotDefault = (value) => {
    return !value.is_default
  }

  const setAsDefault = (name, check) => {
    const id = parseInt(name.substring(3))
    const user = optionalRecipients.filter((value) => value.id === id)[0]
    user.is_default = true
    updateContact(user)
  }

  const setAsNotDefault = (name, check) => {
    const id = parseInt(name.substring(3))
    const user = defaultRecipients.filter((value) => value.id === id)[0]
    user.is_default = false
    updateContact(user)
  }

  const updateContact = async (user) => {
    try {
      await API.put('TALK_IT_API', `/user_email/${user.id}?cognito_id=${cognitoId}`, { body: {
        is_default: user.is_default,
        email_address: user.email_address,
        name: user.name
      } })
      fetchData()
    } catch (error) {
      sendErrorNotification({ title: 'Updating the contact failed. Please try again.' })
    }
  }

  const updateNotificationType = async (name) => {
    setNotificationType(name)
    try {
      await API.put('TALK_IT_API', `/user/${cognitoId}/preferences`, { body: {
        notification_type: name
      } })
      await refreshUser()
    } catch (error) {
      sendErrorNotification({ title: 'Updating user preferences failed. Please try again.' })
    }
  }

  return (
    <AccountPageLayout>
      <Section>
        <Column>
          <SubSection>
            <H3>Note Recipients</H3>
            <Caption>Add individuals that should receive an email when your note is ready.</Caption>
            <Container actionButton={ <Button data-testid="add-contact-button" onClick={() => openModal(<RegisterContactModal onSaveHandler={fetchData}/>)}>Add contact</Button>} outline>
              <InfoPopup label="Default note recipients" message={text.INFO_TEXT.defaultNote} />
              <SubSection>
                <Container>
                  {!loading
                    ? defaultRecipients && defaultRecipients.length > 0 ? <RecipientList data-testid="recipientlist">
                      {defaultRecipients.map((userEmail, index) =>
                        <ListItem key={index}>
                          <RecipientClickArea data-testid={'openRecipientModal' + userEmail.id} onClick={() => openModal(<RegisterContactModal id={userEmail.id} onSaveHandler={fetchData} value={userEmail}/>)}/>
                          <Avatar email={userEmail.email_address} fullName={userEmail.name} initials={getInitialsFromName(userEmail.name)} small="true" />
                          <Checkbox data-testid={'cd_' + userEmail.id} isChecked={userEmail.is_default} name={'cd_' + userEmail.id} onChange={setAsNotDefault} small="true" />
                        </ListItem>)}
                    </RecipientList> : <EmptyState data-testid="defaultNoteEmpty" ><H3>You don't seem to have any default note recipients.</H3></EmptyState> : <LoadingSpinner/>}
                </Container>
              </SubSection>
              <InfoPopup label="Optional note recipients" message={text.INFO_TEXT.optionalNote} />
              {!loading
                ? optionalRecipients && optionalRecipients.length > 0 ? <RecipientList data-testid="recipientlist">
                  {optionalRecipients.map((userEmail, index) =>
                    <ListItem key={index}>
                      <RecipientClickArea onClick={() => openModal(<RegisterContactModal id={userEmail.id} onSaveHandler={fetchData} value={userEmail}/>)}/>
                      <Avatar email={userEmail.email_address} fullName={userEmail.name} initials={getInitialsFromName(userEmail.name)} small="true" />
                      <Checkbox isChecked={userEmail.is_default} name={'cb_' + userEmail.id} onChange={setAsDefault} small="true" />
                    </ListItem>)}
                </RecipientList> : <EmptyState><H3>You don't seem to have any optional note recipients.</H3></EmptyState> : <LoadingSpinner/>}
            </Container>
          </SubSection>
        </Column>
        <Column>
          <SubSection>
            <H3>Your Note Notifications</H3>
            <Caption>How would you like us to notify your note recipients when your note is ready?</Caption>
            <Container outline>
              <RadioButton data-testid="email_body_radio" isSelected={notificationType === NOTIFICATION_TYPE.BODY} label="Email body" name={NOTIFICATION_TYPE.BODY} onSelected={updateNotificationType}>A copy of your note will be sent to your email</RadioButton>
              <RadioButton isSelected={notificationType === NOTIFICATION_TYPE.ATTACHMENT} label="Email attachment" name={NOTIFICATION_TYPE.ATTACHMENT} onSelected={updateNotificationType}>A copy of your note will be sent to your email as a Word document</RadioButton>
              <RadioButton isSelected={notificationType === NOTIFICATION_TYPE.ALL} label="Both" name={NOTIFICATION_TYPE.ALL} onSelected={updateNotificationType}></RadioButton>
            </Container>
          </SubSection>
        </Column>
      </Section>
    </AccountPageLayout>
  )
}

export default AccountNotifications
