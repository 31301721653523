import React from 'react'
import { Container, BackButton, Content, Img, TextContent } from './index.styles'
import inactive from 'shared/assets/inactive.png'
import ButtonGroup from '../ButtonGroup'
import { Button } from '../Button'
import { colors } from 'shared/colors'
import { mdiArrowLeft } from '@mdi/js'
import { useAuthContext } from 'shared/context/AuthProvider'

function InactiveLayout (props) {
  const { signOut } = useAuthContext()

  return (
    <Container {...props}>
      <BackButton>
        <Button iconColor={colors.BLACK} iconName={mdiArrowLeft} onClick={signOut} text>Back to sign in</Button>
      </BackButton>
      <Content role="contentinfo">
        <Img alt="Person losing access to their notes." src={inactive}></Img>
        <TextContent>
          <h2>{props.title}</h2>
          <p>{props.caption}</p>
          <ButtonGroup>
            {props.children}
          </ButtonGroup>
        </TextContent>
      </Content>
    </Container>
  )
}

export default InactiveLayout
