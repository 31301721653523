import styled from 'styled-components'
import emptyIllustrationPng from 'shared/assets/es-notes.svg'
import { colors } from 'shared/colors'

export const EmptyNoteContainer = styled.div`
    display: flex;
    flex: 1;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: Nunito Sans, sans-serif;
    color: ${colors.GREY_700};
`

export const EmptyIllustration = styled.div`
    background-image: url(${emptyIllustrationPng});
    height: 15.5rem;
    width: 13.5rem;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 1.25rem;
`
