import React, { useState, useEffect } from 'react'
import Input from 'shared/components/Input'
import { useAuthContext } from 'shared/context/AuthProvider'
import { isValidEmail } from 'shared/validators'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { ModalDescription } from '../Text/.'
import { AvatarWrapper, DeleteWrapper, CheckboxWrapper } from './index.styles'
import Icon from '@mdi/react'
import { mdiTrashCan } from '@mdi/js'
import Checkbox from 'shared/components/Checkbox'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import Avatar from '../Avatar'
import { getInitialsFromName } from 'shared/extractors'
import Container from '../Container'
import { API } from 'aws-amplify'
import { colors } from 'shared/colors'

const RegisterContactModal = ({ onSaveHandler, value, id }) => {
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const [field, setField] = useState({ id: '', name: '', email_address: '', is_default: false })
  const [isEdit, setIsEdit] = useState(false)
  const [fieldError, setFieldError] = useState({ name: '', email_address: '' })
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuthContext()
  const { attributes: { sub: cognitoId } } = currentUser

  useEffect(() => {
    if (value) {
      setIsEdit(true)
      setField(value)
    }
    document.getElementById('contactName').focus()
  }, [value])

  const updateNameField = (event) => {
    setField({ ...field, name: event.target.value })
    if (event.target.value.trim().length > 2) setFieldError({ ...fieldError, name: '' })
  }

  const updateEmailField = (event) => {
    setField({ ...field, email_address: event.target.value })
    if (isValidEmail(event.target.value.trim())) setFieldError({ ...fieldError, email_address: '' })
    validateField(event)
  }

  const updateDefaultField = (name, checked) => {
    setField({ ...field, is_default: checked })
  }

  const isEmailFieldValid = () => {
    const email = field.email_address.trim()
    return isValidEmail(email)
  }

  const isNameFieldValid = () => field.name.trim().length > 1

  const isValidEmailLength = (email) => {
    const validEmailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return validEmailPattern.test(email)
  }

  const validateField = (event) => {
    const email = event ? event.target.value : field.email_address
    let emailError = <></>
    const customerServiceNumber = <a href={'tel:888-373-1916'}>(888) 373-1916</a>
    const domainError =
        <>For information security, we require that your email recipient’s address use a private domain, preferably a work email. Please contact support at {customerServiceNumber} with any questions or concerns.</>
    if (!isValidEmail(email)) emailError = domainError
    if (!isValidEmailLength(email)) emailError = 'Please enter a valid email address.'
    setFieldError({
      name: isNameFieldValid() ? '' : 'The name you entered is too short.',
      email_address: emailError })
  }

  const isFormValid = () => {
    return field.name.trim() !== '' && field.email_address.trim() !== '' && isEmailFieldValid() && isNameFieldValid()
  }

  const addContact = async () => {
    validateField()
    if (!isFormValid()) return
    try {
      if (loading) return
      setLoading(true)
      await API.post('TALK_IT_API', `/user_email?cognito_id=${cognitoId}`, { body: {
        email_address: field.email_address.trim(),
        name: field.name.trim(),
        is_default: field.is_default
      } })
      sendSuccessNotification({ title: 'Added a new contact.' })
      if (onSaveHandler) onSaveHandler()
      closeModal()
    } catch (error) {
      if (error.request && error.request.status === 409) {
        sendErrorNotification({ title: 'The entered email address is already registered.' })
        setLoading(false)
      } else {
        sendErrorNotification({ title: 'Add contact failed. Please try again.' })
        setLoading(false)
      }
    }
  }

  const updateContact = async () => {
    validateField()
    if (!isFormValid()) return

    try {
      if (loading) return
      setLoading(true)
      await API.put('TALK_IT_API', `/user_email/${id}?cognito_id=${cognitoId}`, { body: {
        email_address: field.email_address.trim(),
        name: field.name.trim(),
        is_default: field.is_default
      } })
      sendSuccessNotification({ title: 'Contact contact was updated.' })
      if (onSaveHandler) onSaveHandler()
      closeModal()
    } catch (error) {
      if (error.request && error.request.status === 409) {
        sendErrorNotification({ title: 'The entered email address is already in use.' })
        setLoading(false)
      } else {
        sendErrorNotification({ title: 'Updating the contact failed. Please try again.' })
        setLoading(false)
      }
    }
  }

  const deleteContact = async () => {
    try {
      if (loading) return
      setLoading(true)
      await API.del('TALK_IT_API', `/user_email/${id}?cognito_id=${cognitoId}`)
      sendSuccessNotification({ title: 'Deleted registered contact' })
      if (onSaveHandler) onSaveHandler()
      closeModal()
    } catch (error) {
      sendErrorNotification({ title: 'Deleting the contact failed. Please try again.' })
      setLoading(false)
    }
  }

  const modalDialogue = {
    primary: {
      onClick: isEdit ? updateContact : addContact,
      disabled: !isFormValid() || loading,
      children: isEdit ? loading ? <LoadingSpinner /> : 'Save' : loading ? <LoadingSpinner /> : 'Add Contact'
    },
    secondary: {
      onClick: closeModal,
      disabled: loading
    }
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={isEdit ? 'Contact Details' : 'Add Contact'}>
      {!isEdit ? <ModalDescription>Try to add your contact with the name you will reference them when leaving dictations. </ModalDescription> : null}
      {isEdit
        ? <Container style={{ display: 'flex', width: '100%' }}> <AvatarWrapper><Avatar email={field.email_address.trim()} fullName={field.name.trim()} initials={getInitialsFromName(field.name.trim())} /></AvatarWrapper>
          {!field.is_primary
            ? <DeleteWrapper>
              <Icon color={colors.RED} data-testid="delete-icon"
                onClick={deleteContact}
                path={mdiTrashCan}
                size={1.25}
                style={{ cursor: 'pointer' }}></Icon>
            </DeleteWrapper>
            : null }
        </Container> : null}

      <div style={{ width: '100%' }}>
        {!field.is_primary && (
          <>
          <Input
            data-testid="contact-name"
            error={fieldError.name}
            id='contactName'
            label="Contact name"
            maxLength={300}
            name="contactName"
            onChange={updateNameField}
            placeholder="What’s their name?"
            value={field.name} />
          <Input
            data-testid="contact-email"
            error={fieldError.email_address}
            id='contactEmail'
            label="Contact email"
            maxLength={150}
            name="contactEmail"
            onChange={updateEmailField}
            placeholder="Ex: john.smith@company.com"
            type="email"
            value={field.email_address} />
            </>
        )}
        <CheckboxWrapper>
          <Checkbox isChecked={field.is_default} label="Default note recipient" onChange={updateDefaultField} />
        </CheckboxWrapper>
      </div>

    </Modal>
  )
}

export default RegisterContactModal
