import { mdiAccountCircle, mdiAssistant, mdiFileDocument } from '@mdi/js'

export const SIDEBAR_NAVIGATION_CONFIG = [
  { to: '/assistant', title: 'Assistant', icon: mdiAssistant },
  { to: '/notes', title: 'Notes', icon: mdiFileDocument },
  { to: '/account', title: 'Account', icon: mdiAccountCircle }
]

export const STANDARD_ACCOUNT_NAVIGATION_CONFIG = [
  { to: '/account/overview', title: 'Overview & Security' },
  { to: '/account/notifications', title: 'Notifications' },
  { to: '/account/integrations', title: 'Integrations' }
]

const ADMIN_ACCOUNT_NAVIGATION_CONFIG = [
  { to: '/account/overview', title: 'Overview & Security' },
  { to: '/account/notifications', title: 'Notifications' },
  { to: '/account/billing', title: 'Billing' },
  { to: '/account/integrations', title: 'Integrations' }
]

export const getAccountRoutes = (isAdmin) => {
  if (isAdmin) {
    return ADMIN_ACCOUNT_NAVIGATION_CONFIG
  }

  return STANDARD_ACCOUNT_NAVIGATION_CONFIG
}
