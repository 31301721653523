import React from 'react'
import Icon from '@mdi/react'
import { P1 } from './../Text/.'
import { LineUsageContainer, UsageBar, LineFill, Overage } from './index.styles'
import { mdiAlert } from '@mdi/js'
import { colors } from 'shared/colors'

const LineUsage = (props) => {
  const totalLineUsagePercentage = (props.linesUsed / props.linesAvailable) * 100
  const hasOverage = () => props.linesUsed > props.linesAvailable

  if (props.linesAvailable > 0) {
    return (
      <LineUsageContainer>
        <UsageBar>
          <LineFill overage={hasOverage()} style={{ width: `${totalLineUsagePercentage}%` }}/>
        </UsageBar>
        <P1>{props.linesUsed} of {props.linesAvailable} lines used</P1>
        {hasOverage() && <Overage><Icon color={colors.RED} path={mdiAlert} size={1} /><P1 style={{ flex: '1', marginLeft: '0.5rem', marginTop: '0' }}>Wow, you have dictated a lot! Your next billing cycle will include additional charges for lines over your subscription.</P1></Overage>}
      </LineUsageContainer>
    )
  }

  return null
}

export default LineUsage
