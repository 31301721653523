import styled from 'styled-components'
import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const TabContainer = styled('div')`
    position: absolute;
    margin-bottom: 3rem;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    max-width: 575px;
    font-family: Nunito Sans;
    font-style: normal;
    font-size: 1rem;
    line-height: 100%;
    letter-spacing: 0.5px;
    background: ${colors.WHITE};
    border: 2px solid ${colors.BLUE};
    border-radius: 15px;
    box-shadow: 0 .2rem .875rem ${colors.TRANSPARENT};
    display: none;
    z-index: 9999;
    transition: .25s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 .25rem .95rem ${colors.BLACK_A20};
    }

    ${media.mobileNav} {
        display: block;
    }

`

export const TabHeader = styled('div')`
    padding: .75rem;
    margin: 0;
    font-weight: bold;
    border-radius: 10px;
    color: ${colors.BLACK};
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    transition: .25s ease;

    &:hover {
        cursor: pointer;
    }
`

export const TabNavList = styled('div')`
    transition: .25s ease;
`

export const Divider = styled('div')`
    width: calc(100% - 2rem);
    height: 2px;
    background: ${colors.GREY_200};
    margin: 0 auto;
`

export const TabItems = styled('ul')`
  padding: .25rem;
  margin: 0;
  transition: .25s ease;

  &:first-child {
  }
`

export const Item = styled('li')`
    margin-top: .25rem;
    list-style: none;
    color: ${colors.BLACK};
    border-radius: 10px;
    user-select: none;
    display: flex;
    transition: .25s ease;
`

export const ItemLink = styled((props) => <RouterLink {...props}/>)`
    font-weight: bold;
    padding: .75rem;
    width: 100%;
    border-radius: 10px;
    color: ${colors.BLACK};
    opacity: .6;
    user-select: none;
    transition: .25s ease;
    
    &:hover {
        cursor: pointer;
        color: ${colors.BLACK};
        opacity: 1;
        background: ${colors.BLUE_200};
    }

    &:active {
        color: ${colors.BLACK};
        opacity: .4;
    }

    &:visited {
        color: ${colors.BLACK};
    }
`
