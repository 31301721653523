import React from 'react'
import { StyledInput, SearchBarWrapper, ButtonWrapper, StyledSearchBar } from './index.styles'
import { Button } from '../Button/.'
import { mdiMagnify, mdiClose } from '@mdi/js'
import { colors } from 'shared/colors'

function SearchBar (props) {
  const inputRef = React.createRef()

  const focusSearchBar = () => {
    inputRef.current.focus()
  }

  return (
    <StyledSearchBar>
      <SearchBarWrapper>
        <StyledInput data-testid="searchInput" onChange={props.onChange} placeholder="Search notes" ref={inputRef} type="text" value={props.value} />
        <ButtonWrapper>
          {props.value ? <Button data-testid="clearControl" iconColor={colors.BLACK} iconName={mdiClose} onClick={props.onSearchClear} type="button" text /> : <Button data-testid="searchControl" iconColor={colors.WHITE} iconName={mdiMagnify} onClick={focusSearchBar} type="button" />}
        </ButtonWrapper>
      </SearchBarWrapper>
    </StyledSearchBar>
  )
}

export default SearchBar
