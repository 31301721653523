import styled from 'styled-components'
import { colors } from 'shared/colors'

export const PendingNoteContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Nunito Sans, sans-serif;

    h1 {
        font-size: 1.56rem;
        font-weight: bold;
        color: ${colors.BLACK};
        margin: .75rem 0 .5rem 0;
    }

    p {
        color: ${colors.BLACK};
        margin: 0 0 1.5rem 0;
    }
`
