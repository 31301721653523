import React from 'react'
import { CompletedNoteContainer, FloatingActions } from './index.styles'
import { Button } from 'shared/components/Button'
import { mdiDownload, mdiEmail } from '@mdi/js'
import { colors } from 'shared/colors'
import { formatStandardDateTime, formatNoteEmailBody, formatContactName } from 'shared/formatters'
import Metadata from 'shared/components/Metadata'

function CompletedNote (props) {
  const { content,
    currentUser,
    dictated_on: dictatedOn,
    created_on: createdOn,
    job_id: jobId,
    line_count: lineCount,
    file_url: fileUrl,
    contact_first_name: contactFirstName,
    contact_last_name: contactLastName } = props
  const { name } = currentUser.attributes
  const sanitizedContent = content || ''
  const metadataItems = [
    { title: 'Contact Name', data: formatContactName(contactFirstName, contactLastName) },
    { title: 'Dictated By', data: name },
    { title: 'Dictated On', data: formatStandardDateTime(dictatedOn) },
    { title: 'Transcribed On', data: formatStandardDateTime(createdOn) },
    { title: 'Job Number', data: jobId },
    { title: 'Lines Used', data: lineCount }
  ]
  const emailBody = formatNoteEmailBody(sanitizedContent, metadataItems)
  const emailSubject = props.subject || ''

  return (
    <CompletedNoteContainer {...props}>
      <FloatingActions>
        {fileUrl && <Button data-testid="downloadNoteAttachment" href={fileUrl} iconColor={colors.BLACK} iconName={mdiDownload} text />}
        <Button data-testid="emailNoteAction" href={`mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`} iconColor={colors.BLACK} iconName={mdiEmail} text />
      </FloatingActions>
      {props.subject && <h1>{props.subject}</h1>}
      {sanitizedContent && sanitizedContent.split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
      <Metadata items={metadataItems} />
    </CompletedNoteContainer>
  )
}

export default CompletedNote
