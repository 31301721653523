import styled from 'styled-components'
import { media } from 'shared/breakpoints'

export const UpdateButtonContainer = styled.div`
    width: 226px;
    margin: 0 auto 2rem 0;

    ${media.mobileNav} {
        margin-bottom: 1rem;
    }
`
