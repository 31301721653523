import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import InactiveBanner from '../InactiveBanner'
import LoadingSpinner from '../LoadingSpinner'
import { StyledLayout, StyledPage, StyledApp } from './index.styles'
import { useAuthContext } from 'shared/context/AuthProvider'
import { PrivateRoutesForApp, PublicRoutesForApp } from 'shared/routes'
import { getTalkItUser, getIsLegalAgreementRequired } from 'shared/extractors'
import { colors } from 'shared/colors'
import { useModalContext } from 'shared/context/ModalProvider'
import LegalModal from '../LegalModal'

function Layout () {
  const { loading, currentUser } = useAuthContext()
  const { openModal } = useModalContext()
  const [legalModalOpen, setLegalModalOpen] = useState(false)
  const { isInactive, isTrialUser } = getTalkItUser(currentUser)
  const isLegalAgreementRequired = getIsLegalAgreementRequired(currentUser)
  const showTrialBanner = isTrialUser && !isInactive

  if (loading) {
    return (
      <StyledLayout data-testid="loading-layout" center>
        <LoadingSpinner backColor={colors.BLUE} frontColor={colors.BLUE_200} size={60} />
      </StyledLayout>
    )
  }

  if (!currentUser) {
    return <PublicRoutesForApp />
  }

  if (!legalModalOpen && isLegalAgreementRequired) {
    openModal(<LegalModal />)
    setLegalModalOpen(true)
  }

  return (
    <StyledApp>
      {showTrialBanner && <InactiveBanner data-testid="upgrade-now-bar" />}
      <StyledLayout withBanner={showTrialBanner}>
        {!isInactive && <Sidebar data-testid="sidebar" />}
        <StyledPage>
          <PrivateRoutesForApp />
        </StyledPage>
      </StyledLayout>
    </StyledApp>
  )
}

export default Layout
