import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: ${colors.WHITE};
    z-index: 99999;
`

export const BackButton = styled.div`
    display: flex;
    padding: .625rem;
`

export const StyledLayout = styled.div`
    background-color: ${colors.WHITE}
    display: flex;
    height: 100vh;
    flex-direction: column;

    ${props => props.center && css`
        justify-content: center;
        align-items: center;
    `}
`
