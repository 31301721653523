import styled from 'styled-components'
import { colors } from 'shared/colors'

export const ListItem = styled.li`
    position: relative;
    list-style: none;
    padding: 0.53rem;
    border-radius: .9rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3.25rem;
    transition: all .25s ease;
    color: ${colors.BLACK};
    background: ${colors.TRANSPARENT};

    span {
        display: flex;
        align-items: center;

        label {
            position: absolute;
            right: .53rem;
        }
    }

    :hover {
        cursor: pointer;
        background: ${colors.GREY_100};
    }
    :active {
        opacity: .5;
    }

`
