import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const H1 = styled.h1`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 0 1rem;

    ${props => props.center && css`
        text-align: center;
    `}
`

export const H2 = styled.h2`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.565rem;
    line-height: 1.695rem;
    margin: 0 0 .875rem;

    ${props => props.center && css`
        text-align: center;
    `}
`

export const H3 = styled.h3`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin: ${props => (props.noSpace ? '0' : '0 0 .5rem')};


    ${props => props.center && css`
        text-align: center;
    `}
`

export const H4 = styled.h4`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.3rem;
    margin: ${props => (props.noSpace ? '0' : '0 0 .25rem')};
    color: ${props => props.color}
    ${props => props.center && css`
        text-align: center;
    `}
`

export const P1 = styled.p`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0;
`

export const ModalDescription = styled.p`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: normal;
    margin: 0 0 1rem;
    font-size: 1rem;
    line-height: 157%;
`

export const B1 = styled.p`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0;
`

export const P2 = styled.p`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
`

export const B2 = styled.p`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
`

export const Action1 = styled.span`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.03rem;
    margin: 0;
`

export const Action2 = styled.span`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    margin: 0;
`

export const Caption = styled.span`
    font-family: 'Nunito Sans',sans-serif;
    font-style: normal;
    font-weight: normal;
    display: block;
    line-height: 1.2rem;
    margin: ${props => (props.noSpace ? '0' : '0 0 .5rem')};
`

export const AnchorLink = styled.a`
    text-decoration: none;
    color: ${colors.BLUE};
    font-weight: bold;
    margin: 0;

    :hover {
        color: ${colors.BLUE};
        cursor: pointer;
    }
`

export const IconWithText = styled.div`
    display: flex;

    p {
        margin: -0.15rem 0 0 .5rem;
    }

    svg {
        min-width: 1.5rem;
        min-height: 1.5rem;
    }
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: inherit;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${colors.BLUE};
    margin: 1rem auto;
`

export const Error = styled.div`
    font-size: 0.9rem;
    font-family: 'Nunito Sans',sans-serif;
    font-weight: normal;
    margin: 0.5rem 0 0.75rem 0;
    color: ${colors.RED};
    text-align: center;
`

export const Tab = styled(({ text, active, ...props }) => <RouterLink {...props}/>)`
    font-family: 'Nunito Sans',sans-serif;    
    font-style: normal;
    font-weight: bolder;
    font-size: 0.875rem;
    text-decoration: none;
    opacity: 0.6;
    transition: .25s ease;
    border-radius: .75rem .75rem 0 0;

    ${props => props.active && css`
        opacity: 1;
        color: ${colors.BLUE} !important;
    `}

    :hover {
        opacity: 1;
    }
`
