import React, { useState, useEffect, useCallback } from 'react'
import { StyledNotes, StyledNotesList, NoteDetailContainer, FloatingActions, NoNoteMessage, FloatingUploadAction, AudioBar, NoteList, NoteListItem, EmptyStateGraphic } from './index.styles'
import Note from '../ListNoteItem'
import PendingNote from '../PendingNote'
import CompletedNote from '../CompletedNote'
import EmptyNote from '../EmptyNote'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { Button } from 'shared/components/Button'
import { H3 } from 'shared/components/Text'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { mdiArrowLeft, mdiUpload } from '@mdi/js'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import useDebounce from 'shared/debouncers'
import UploadAudioModal from 'shared/components/UploadAudioModal'
import SearchBar from 'shared/components/SearchBar'
import { ReactComponent as EmptyState } from 'shared/assets/es-search.svg'
import { API } from 'aws-amplify'
import { log } from 'shared/logger'
import events from 'shared/events'
import { colors } from 'shared/colors'

function ListNotes () {
  const [notes, setNotes] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searching, setSearching] = useState(false)
  const [loading, setLoading] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)
  const [nextPage, setNextPage] = useState(1)
  const [selectedNote, setSelectedNote] = useState(null)
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null)
  const { openModal } = useModalContext()
  const { currentUser } = useAuthContext()
  const { attributes: { sub: cognitoId } = {} } = currentUser || {}

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  if (debouncedSearchTerm === '' && searching) {
    setLoading(true)
    setNotes([])
    setNextPage(0)
    setHasNextPage(true)
    setSearching(false)
  }

  if (debouncedSearchTerm && searching === false) {
    setLoading(true)
    setNotes([])
    setNextPage(0)
    setHasNextPage(true)
    setSearching(true)
    log(events.NOTES_SEARCH)
  }

  const selectNote = (note, index) => {
    if (note !== selectedNote) {
      setSelectedNoteIndex(index)
      return setSelectedNote(note)
    }

    return unselectNote()
  }

  const unselectNote = () => {
    setSelectedNoteIndex(null)
    return setSelectedNote(null)
  }

  const fetchNoteList = async (cognitoId, searchTerm, page = 0) => {
    const itemsPerPage = 20
    const searchParameter = searchTerm ? `&search_term=${searchTerm}` : ''
    const offsetParameter = `&offset=${(page * itemsPerPage)}`

    if (!cognitoId) {
      return []
    }

    try {
      const nextNotesPage = (await API.get('TALK_IT_API', `/note?cognito_id=${cognitoId}&limit=${itemsPerPage}${offsetParameter}${searchParameter}`)).data
      const sanitizedNextNotesPage = nextNotesPage.filter(note => note.status !== 'unsubmitted')

      if (sanitizedNextNotesPage.length < itemsPerPage) {
        setHasNextPage(false)
      }

      return sanitizedNextNotesPage
    } catch (error) {
      setHasNextPage(false)
      return []
    }
  }

  const fetchMoreNotesList = async () => {
    setLoading(true)
    const nextNotesPage = await fetchNoteList(cognitoId, debouncedSearchTerm, nextPage)
    setNextPage(nextPage + 1)
    setNotes([...notes, ...nextNotesPage])
    setLoading(false)
  }

  const fetchInitialNotesList = useCallback(async () => {
    setLoading(true)
    setNotes(await fetchNoteList(cognitoId, debouncedSearchTerm))
    setLoading(false)
  }, [cognitoId, debouncedSearchTerm])

  useEffect(() => {
    fetchInitialNotesList()
  }, [fetchInitialNotesList])

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMoreNotesList,
    scrollContainer: 'parent'
  })

  const onSearchTermChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const onSearchTermClear = () => {
    setSearchTerm('')
  }

  const selectedCompletedNote = (selectedNote && selectedNote.status === 'completed')
  const selectedPendingNote = (selectedNote && selectedNote.status === 'pending')
  const selectedNoteWithAudio = (selectedNote && selectedNote.audio_s3_url)
  const isOpened = selectedNoteIndex !== null
  const hasNotes = !!notes.length

  return (
    <StyledNotes>
      <FloatingUploadAction>
        <Button iconColor={colors.WHITE} iconName={mdiUpload} id="uploadAudio" onClick={() => openModal(<UploadAudioModal />)} fab>Upload Audio</Button>
      </FloatingUploadAction>
      <StyledNotesList data-testid="notesContainer">
        <SearchBar onChange={onSearchTermChange} onSearchClear={onSearchTermClear} value={searchTerm} />
        <NoteList ref={infiniteRef}>
          {hasNotes && notes.map((note, index) => <NoteListItem key={index}><Note {...note} data-testid={`note-${index}`} onClick={() => selectNote(note, index)} selected={selectedNoteIndex === index} /></NoteListItem>)}
          {!loading && !hasNotes && searchTerm && <NoNoteMessage data-testid="emptyNotesList" center><EmptyStateGraphic><EmptyState/><H3 center>Sorry, we couldn’t find any matching notes for that.</H3></EmptyStateGraphic></NoNoteMessage>}
          {loading && <LoadingSpinner space/>}
        </NoteList>
      </StyledNotesList>
      <NoteDetailContainer opened={isOpened}>
        {selectedNote && <FloatingActions opened={isOpened}>
          <Button iconName={mdiArrowLeft} onClick={unselectNote} text />
        </FloatingActions>}
        {selectedCompletedNote && <CompletedNote {...selectedNote} currentUser={currentUser} data-testid="completedNote" />}
        {selectedPendingNote && <PendingNote {...selectedNote} data-testid="pendingNote" />}
        {selectedNoteWithAudio && <AudioBar data-testid="audioBar" src={selectedNote.audio_s3_url} controls />}
        {!selectedNote && <EmptyNote data-testid="emptyNote" />}
      </NoteDetailContainer>
    </StyledNotes>
  )
}

export default ListNotes
