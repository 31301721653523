import React from 'react'
import { LinkButton, Button } from '../Button'
import { UpdateButtonContainer } from './index.styles'
import { useAuthContext } from 'shared/context/AuthProvider'
import { isAllowedToUpgradePlan, isAllowedToUpdatePaymentMethod } from 'shared/validators'
import PaymentProfileModal from '../PaymentProfileModal'
import { useModalContext } from 'shared/context/ModalProvider'

function UpdateBillingButton (props) {
  const { currentUser } = useAuthContext()
  const { openModal } = useModalContext()

  if (isAllowedToUpgradePlan(currentUser)) {
    return (
      <UpdateButtonContainer {...props}>
        <LinkButton to="/account/billing/upgrade">Upgrade Account Now</LinkButton>
      </UpdateButtonContainer>
    )
  }

  if (isAllowedToUpdatePaymentMethod(currentUser)) {
    return (
      <UpdateButtonContainer {...props}>
        <Button onClick={() => openModal(<PaymentProfileModal />)}>Update Payment Method</Button>
      </UpdateButtonContainer>
    )
  }

  return null
}

export default UpdateBillingButton
