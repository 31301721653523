import { Auth } from 'aws-amplify'

const constants = Object.freeze({
  AWS: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_uzHmxAZ1m',
      userPoolWebClientId: '587l01ptbj4b0lqasov15akuaf',
      identityPoolId: 'us-east-1:5283646f-58cd-44a8-968f-8f9ecb8b30ee',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'TALK_IT_API',
          endpoint: 'https://xb2aaj19gl.execute-api.us-east-1.amazonaws.com/prod'
        },
        {
          name: 'TALK_IT_API_ADMIN',
          endpoint: 'https://xb2aaj19gl.execute-api.us-east-1.amazonaws.com/prod',
          custom_header: async (h) => {
            return { AuthToken: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          }
        }
      ]
    }
  },
  TALK_IT_API: {
    HOST: 'https://xb2aaj19gl.execute-api.us-east-1.amazonaws.com/prod'
  },
  SENTRY: {
    DSN: 'https://b06c4d2911a248d8929aa315bae1e109@sentry.io/1406883'
  },
  AMPLITUDE: {
    API_KEY: '78391f0ccf3eac09af5069913e838c71'
  },
  GOOGLE_ANALYTICS_API_KEY: 'UA-19758811-1',
  RECAPTCHA_API_KEY: '6Lc42LYZAAAAABwO7tz4PSgXGSAfuS3AOqjRx9q2',
  THANKYOU_REDIRECT_URL: 'https://mobileassistant.us/free-trial-thank-you/',
  TIAA_IDS: [15550, 15575, 15739, 16835, 16953, 17802, 19080, 19158, 20481, 22581, 24838]
})

export default constants
