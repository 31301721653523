import styled from 'styled-components'

export const DisclaimerText = styled.div`
    padding: 0 0 1rem 0;
    max-width: 700px;
`

export const ItemsList = styled.ul`
    list-style: none;
    padding: 0;
    max-width: 700px;

    li:last-of-type {
        div {
            &:after {
                content: none;
            }
        }
    }
`

export const IntegrationItems = styled.li`
    max-width: 100%;
`
