import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const LinesSection = styled.div`
    margin: 0 0 1.5rem 0;

    ${media.mobileNav} {
        margin-top: 1rem;
    }
`

export const Divider = styled.div`
    background: ${colors.GREY_200};
    width: 100%;
    height: 2px;
    top: 0;
    boarder-radius: 2px;
    position: absolute;
`

export const Section = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    max-width: 48.875rem;
    padding: 1.875rem 0;

    @media (max-width: 930px) {
        flex-direction: column;
        padding-bottom: 2rem;
    }

    ${media.mobileNav} {
        padding-bottom: 0;
    }
`

export const Column = styled.div`
    max-width: 23.5rem;
    width: cacl(100% - 1.875rem);

    @media (max-width: 1030px) {
        max-width: calc(50% - .9rem);
    }
    @media (max-width: 930px) {
        max-width: 100%;
        margin-bottom: 1rem;
    }
`

export const SubSection = styled.div`
    margin-bottom: 1.875rem;

    &:last-child {
        margin-bottom: 1rem;
    }
`

export const DictatorID = styled.div`
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 125%;
    display: flex; 
    align-items: center;
    letter-spacing: 0.25em;
    color: ${colors.BLACK};
    background: ${colors.GREY_100};
    border-radius: .9rem;
    padding: .875rem;
    margin-bottom: 1rem;
`

export const PhoneList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

export const AppDownloadButtons = styled.div`
    display: flex;
    width: 100%;

    a, img {
        width: auto;
        height: 50px;
    }

    a:first-child {
        margin-right: 15px;
    }

    ${media.smaller} {
        a, img {
            width: auto;
            height: 42px;
        }
    }


`

export const Version = styled.div`
    font-size: 0.75rem;
    margin: 1rem 0;
`
