import React from 'react'
import InactiveLayout from 'shared/components/InactiveLayout'
import { Button } from 'shared/components/Button/index'
import { mdiFaceAgent } from '@mdi/js'
import { useModalContext } from 'shared/context/ModalProvider'
import { colors } from 'shared/colors'
import HelpModal from 'shared/components/HelpModal'
import UpdateBillingButton from 'shared/components/UpdateBillingButton'

function InactiveUnpaid (props) {
  const { openModal } = useModalContext()

  return (
    <InactiveLayout caption="To regain access to your account and Mobile Assistant’s services, simply update your billing details or contact our support for help." title="Oh no! It looks like your account is inactive." {...props}>
      <UpdateBillingButton />
      <Button iconColor={colors.BLACK} iconName={mdiFaceAgent} onClick={() => openModal(<HelpModal/>)} inverse>Need Help?</Button>
    </InactiveLayout>
  )
}

export default InactiveUnpaid
