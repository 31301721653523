import styled from 'styled-components'
import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'

export const CompletedNoteContainer = styled.div`
    flex: 1;
    font-family: Nunito Sans, sans-serif;
    color: ${colors.BLACK};
    width: 100%;
    padding: 4.2rem 1rem 4rem; 
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;

    h1 {
        font-size: 2rem;
        line-height: 2.15rem;
        margin: 1.88rem 0;
        width: 100%;
    }

    p {
        font-size: 1.1rem;
        margin: 0.5rem 0;
        line-height: 1.65rem;
        width: 100%;
    }
    @page {
        size: auto;
        margin: 0;
    }
}
`

export const FloatingActions = styled.div`
    display: flex;
    position: absolute;
    right: 1rem;
    top: 1rem;
    ${media.print} {
        display: none;
    }
`
