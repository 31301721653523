import React from 'react'
import { LinkButton } from '../Button'
import { mdiAlertCircleOutline } from '@mdi/js'
import Banner from '../Banner'
import { useAuthContext } from 'shared/context/AuthProvider'
import { colors } from 'shared/colors'
import { isAllowedToUpgradePlan } from 'shared/validators'

function InactiveBanner (props) {
  const { currentUser } = useAuthContext()

  return (
    <Banner
      action={isAllowedToUpgradePlan(currentUser) ? <LinkButton to="/account/billing/upgrade" banner>Upgrade Now</LinkButton> : undefined}
      icon={mdiAlertCircleOutline}
      iconColor={colors.YELLOW}
      title="You are in a free trial"
      {...props} />
  )
}

export default InactiveBanner
