import React from 'react'
import { ItemWrapper, Content, StaticContent, Image } from './index.styles'
import { Button } from '../Button/'
import { H4, Caption } from '../Text/.'

const IntegrationItem = ({ key, title, children, imagePath, installStatus, guideLink, ...props }) => {
  return (
    <ItemWrapper>
      <Image src={imagePath}/>
      <Content>
        <StaticContent>
          <H4>{title}</H4>
          <Caption>{children}</Caption>
        </StaticContent>
        { installStatus ? <Button href={guideLink} target='_blank' inverse small>Quick start guide</Button> : null}
      </Content>
    </ItemWrapper>
  )
}

export default IntegrationItem
