import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const Area = styled.div`
    position: relative; 
    height: 100%;
    overflow-y:scroll;
    color: ${colors.BLACK};

    ${media.tablet} {
        height: calc(100% - 4rem);
    }
`

export const Header = styled.div`
    margin-bottom: 2rem;

    h1 {
        padding: 2rem 2rem 0 2rem;
    }

    ${media.mobileNav} {
        padding-bottom: 1rem;

        h1 {
            margin-bottom: .5rem;
            padding: 1rem 1rem 0 1rem;
        }
    }
`

export const Nav = styled.div`
    position: relative;

    ${media.mobileNav} {
        height: 3.25rem;
    }
`

export const Container = styled.div`
    padding: 0 2rem 2rem;

    ${media.mobileNav} {
        padding: 0 1rem 4rem;
    }
`

export const FloatingActions = styled.div`
    position: fixed;
    z-index: 999;
    right: 1.5rem;
    bottom: 1.5rem;

    ${media.tablet} {
        bottom: 4.75rem;
    }
`
