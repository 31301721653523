import styled from 'styled-components'
import { colors } from 'shared/colors'

export const Container = styled.div`
    padding-top: 1rem;
    border-top: 2px solid ${colors.GREY_200};
    color: ${colors.GREY_700};
    width: 100%;
    margin-top: 1rem;
`

export const Row = styled.div`
    padding: 0.25rem 0;
    display: flex;
    justify-content: space-between;
`

export const Title = styled.div`
    font-size: 0.8rem;
    font-weight: bold
`

export const Data = styled.div`
    font-size: 0.8rem;
    font-weight: normal
`
