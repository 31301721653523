import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    ${media.phone} {
        flex-direction: column-reverse;
        align-items: center;
    }
`

export const BackButton = styled.div`
    padding: .625rem;
    max-width: 350px;

    ${media.phone} {
        max-width: 350px;
        width: 100%;
        a {
            width: 100%;
        }
    }

`

export const Content = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: ${colors.BLACK};
  margin: auto;
  max-width: 825px;
  width: 100%;
  padding-left: 1.5rem;
  transform: translateY(-60px);

  ${media.tablet} {
    flex-direction: column;
    transform: translateY(0);
    padding: .625rem;
    align-items: center;
  }
`

export const Img = styled.img`
    width: 430px;
    height: auto;

    ${media.tablet} {
        width: calc(100% - 36%);
        transform: translateX(18%);
        margin-bottom: 1.5rem;
    }

    ${media.phone} {
        width: calc(105% - 36%);
        transform: translateX(18%);
        margin-bottom: 3rem;
    }

`

export const TextContent = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto 0 1rem;
    transform: translateX(-3rem);

    h2 {
        font-style: normal;
        font-weight: 800;
        font-size: 1.75rem;
        line-height: 125%;
        margin: 0;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.4rem;
        margin: .625rem 0 1.875rem;
    }

    ${media.tablet} {
        transform: translateX(0);
        text-align: center;
        justify-content: center;
        align-items: center;
        max-width: 350px;

        div {
            width: 100%;
            padding: 0 .625rem;

            a {
                width: 100%;
            }
        }
    }

    ${media.smaller} { 
        max-width: 370px;
        p {
            margin: .625rem 0 1rem;
        }

        div {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;

            a {
                :first-child {
                    margin: .625rem 0 0;
                }
            }
        }
    }
`
