const events = Object.freeze({
  USER_LOGGED_IN: 'User logged in',
  USER_LOG_IN_FAILED: 'User log in failed',
  USER_REGISTERED: 'User registered',
  USER_RESET_PASSWORD: 'User reset password',
  USER_SIGNED_OUT: 'User signed out',
  USER_PASSTHROUGH_AUTH: 'User logged in with passthrough authentication',

  NAVIGATED_TO_SIGN_UP: 'Navigated to Sign Up View',
  NAVIGATED_TO_FORGOT_PASSWORD: 'Navigated to Forgot Password View',
  NAVIGATED_TO_RECORDING: 'Navigated to Recording View',
  NAVIGATED_TO_NOTES: 'Navigated to Notes View',
  NAVIGATED_TO_NOTE_DETAIL: 'Navigated to Note Detail View',
  NAVIGATED_TO_ASSISTANT: 'Navigated to Assistant View',
  NAVIGATED_TO_ASSISTANT_DETAIL: 'Navigated to Assistant Template Detail View',
  NAVIGATED_TO_ACCOUNT: 'Navigated to Account View',
  NAVIGATED_TO_ACCOUNT_NOTIFICATIONS: 'Navigated to Account Notifications View',
  NAVIGATED_TO_ACCOUNT_INTEGRATIONS: 'Navigated to Account Integrations View',
  NAVIGATED_TO_ACCOUNT_BILLING: 'Navigated to Account Billing View',

  RECORDING_STARTED: 'Recording started',
  RECORDING_PAUSED: 'Recording paused',
  RECORDING_RESUMED: 'Recording resumed',
  RECORDING_SUBMITTED: 'Recording submitted',
  RECORDING_CANCELLED: 'Recording cancelled',
  RECORDING_INTERRUPTED: 'Recording interrupted',

  AUDIO_PLAYED: 'Audio played',

  NOTES_SEARCH: 'Notes search',
  NOTE_TAG: 'Note tag',

  SUPPORT_EMAILED: 'Emailed support',
  SUPPORT_CALLED: 'Called support',

  ASSISTANT_CHANGES_CANCELLED: 'Cancelled changes for Assistant Template',

  EMAIL_APP_OPENED_FROM_VERIFY: 'Opened email app from verify email screen'
})

export default events
