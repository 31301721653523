import React from 'react'
import Icon from '@mdi/react'
import { IconWithText } from '../Text/'
import { MessageWrapper } from './index.styles'
import { colors } from 'shared/colors'

const AlertMessage = (props) => {
  return (
    <MessageWrapper {...props}>
      {props.iconName && <IconWithText>
        <Icon color={colors.BLACK} path={props.iconName} size={1}/>
        {props.children}
      </IconWithText>}
      {!props.iconName && props.children}
    </MessageWrapper>
  )
}

export default AlertMessage
