import React from 'react'
import { ContainerStyle, FloatingButton, ScrollSpace } from './index.styles'

const Container = ({ ...props }) => {
  return (
    <ContainerStyle {...props}>
      {props.children}
      {props.actionButton ? <ScrollSpace/> : null}
      {props.actionButton ? <FloatingButton>{props.actionButton}</FloatingButton> : null}
    </ContainerStyle>
  )
}

export default Container
