import React, { useState } from 'react'
import { ModalDescription, AnchorLink } from '../Text/index'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Modal from '../Modal'
import { getCognitoId } from 'shared/extractors'
import { API } from 'aws-amplify'
import LoadingSpinner from '../LoadingSpinner'

const LegalModal = (props) => {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuthContext()
  const { closeModal } = useModalContext()
  const { sendErrorNotification } = useNotificationContext()
  const cognitoId = getCognitoId(currentUser)

  const acceptAgreements = async () => {
    try {
      if (loading) return
      setLoading(true)
      await API.post('TALK_IT_API', `/legal?cognito_id=${cognitoId}`, { body: {} })
      closeModal()
    } catch (error) {
      sendErrorNotification({ title: 'Legal agreements not accepted, please try again.' })
      setLoading(false)
    }
  }

  const modalDialogue = {
    primary: {
      onClick: acceptAgreements,
      children: loading ? <LoadingSpinner /> : 'Accept and Continue'
    }
  }

  return (
    <Modal dialogue={modalDialogue} title={'Legal Agreements'} {...props}>
      <ModalDescription>By clicking Accept and Continue, you agree to our <AnchorLink href="https://mobileassistant.us/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</AnchorLink> and <AnchorLink href="https://mobileassistant.us/terms" rel="noopener noreferrer" target="_blank">Terms of Agreement</AnchorLink>.</ModalDescription>
    </Modal>
  )
}

export default LegalModal
