import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const LineUsageContainer = styled.div`
    max-width: 380px;
    width: 100%;
    margin-top: 1rem;
    p {
        margin-top: .5rem;
        color: ${colors.BLACK};
    }
`

export const UsageBar = styled.div`
    position: relative;
    height: 1rem;
    width: 100%;
    border-radius: 1.5rem;
    background: ${colors.GREY_200};
`

export const LineFill = styled.div`
    background: ${colors.BLUE};
    height: 100%;
    max-width: 100%;
    border-radius: inherit;
    transition: width: .25s ease;

    ${props => props.overage && css`
        background-color: ${colors.RED};
    `}
`

export const Overage = styled.div`
    display: flex;
    margin-top: 1rem;
`
