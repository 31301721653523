import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'
import styled from 'styled-components'

export const Area = styled.div`
    position: relative; 
    height: 100%;
    overflow-y:scroll;
    color: ${colors.BLACK};

    ${media.tablet} {
        height: calc(100% - 4rem);
    }
`

export const Header = styled.div`
    margin-bottom: 1rem;

    h1 {
        padding: 2rem 2rem 0 2rem;
    }

    ${media.mobileNav} {
        padding-bottom: 1rem;

        h1 {
            margin-bottom: .5rem;
            padding: 1rem 1rem 0 1rem;
        }
    }
`

export const DetailsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

export const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 1.875rem 1rem;

    @media (max-width: 930px) {
        flex-direction: column;
        padding-bottom: 2rem;
    }

    ${media.mobileNav} {
        padding-bottom: 0;
    }
`

export const TemplateList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`
export const Divider = styled.div`
    background: ${colors.GREY_200};
    width: 100%;
    height: 2px;
    border-radius: 2px;
`
