import styled, { css } from 'styled-components'
import { media } from 'shared/breakpoints'
import { Button } from '../Button'
import { H1 } from '../Text'
import { colors } from 'shared/colors'

export const Container = styled.div`
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Footer = styled.div`
    height: 50px;
`

export const Footnote = styled.ul`
    margin: 0.75rem 0;
    color: ${colors.GREY_700};
    padding-inline-start: 0px;
`

export const Plan = styled.div`
    justify-content: space-between;
    width: 300px;
    border-radius: 10px;
    padding: .9rem;
    margin: .75rem;
    border: 4px solid ${props => props.color};

    ${props => props.secondary && css`
        border: none;
        text-align: center;
    `}
`

export const PlanGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    ${media.phone} {
        flex-direction: column;
    }
`

export const PlanTitle = styled(H1)`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0;
`

export const TitleContainer = styled.div`
    margin-bottom: 1.5rem;
    padding: .5rem;
    border-bottom: 1px solid;
`

export const Title = styled.div`
    font-size: .95rem;
    text-align: center;
`

export const SubscriptionFeature = styled.ul`
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;    
    padding-inline-start: 0px;
`

export const SubscriptionFeatureBold = styled.ul`
    font-weight: bold;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    padding-inline-start: 0px;   
`

export const Price = styled(H1)`
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 0;
`

export const SubscriptionUpgradeButton = styled(Button)`
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 250px;
`

export const PlanHeader = styled.div`
    text-align: center;
    margin-bottom: 1rem;

    p {
        font-size: 1.1rem;
    }
`
