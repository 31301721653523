import { colors } from '../../colors'
import styled from 'styled-components'

export const IconWrapper = styled.div`
  color: ${colors.WHITE};
  transition: all .2s ease-in-out;
  cursor: pointer;
`

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${colors.WHITE};
  min-width: 160px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.3);
  z-index: 1;
  border-radius: 10px;
`

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  
  &:hover ${DropDownContent} {
    display: block;
  }
`

export const DropDownItem = styled.div`
  color: ${colors.BLACK};
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  
  &:hover {
    background-color: ${colors.GREY_200};
  }

`
