import styled from 'styled-components'
import { colors } from 'shared/colors'

export const StyledSearchBar = styled.div`
    background: ${colors.GREY_200};
    padding: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 10;
`

export const SearchBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all .3s ease;
    position: relative;
`

export const StyledInput = styled.input`
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    padding: 0.75rem 3rem .75rem .75rem;
    display: flex;
    outline: none;
    border: none;
    align-items: center;
    width: 100%;
    border-radius: 2rem;

    &::-webkit-input-placeholder {
        color: ${colors.BLACK_A40};
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 0.25rem;

    a {
        min-height: 0;
        height: 100%;
        padding: .4rem;
        width: 100%;
        min-width: 100%;

        :hover {
            transform: translate(0, 0);
            box-shadow: 0 0 0 ${colors.TRANSPARENT};
        }
    }
`
