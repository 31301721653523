import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Image = styled.img`
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
    margin-right: 1rem;
    display: flex;
    align-self: center;

    ${media.phone} {
        align-self: flex-start;
        margin-top: 1rem;
    }
`

export const Content = styled.div`
    color: ${colors.BLACK};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 1rem 0;

    &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: ${colors.GREY_200};
        border-radius: 2px;
        position: absolute;
        bottom: 0;
    }

    ${media.phone} {
        flex-direction: column;
        align-items: flex-start;

        a {
            margin-top: .5rem;
        }
    }
`

export const StaticContent = styled.div`
    margin-right: 1.5rem;

    span {
        opacity: .6;
        margin: 0;
    }

    span:first-of-type {
        display: flex;
        align-items: center;
    }

    p {
        margin-top: .25rem;
    }

    ${media.phone} {
        margin-right: 0rem;
    }
`
