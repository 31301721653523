import styled from 'styled-components'

export const EditContainer = styled.div`
    margin-top: 1rem;
    width: 100%;
`

export const TemplateWrapper = styled.div`
    margin: 0 auto;
`

export const Title = styled.div`
    font-size: 2rem;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }
`

export const QuestionContainer = styled.div`
    display: flex;
    border: 2px solid ${props => props.color};
    border-radius: 5px;
    padding: 8px;
    margin-top: 20px;
    input {
        padding: 10px;
    }
`

export const Question = styled.div`
    width: 100%;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }
`

export const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 2rem;
`
