import { getPhoneNumberDigits, getTalkItUser, getTalkItTeam } from './extractors'
import { PAYMENT_PROVIDER, SUBSCRIPTION, PAYMENT_SOURCE } from './enums'

const isValidFullName = (name) => {
  const validNamePattern = /^[a-zA-Z\s']+$/
  const nameParts = name.split(' ')
  return nameParts.length >= 2 && validNamePattern.test(name)
}

const isValidPassword = (password) => {
  return password.length >= 7
}

const isValidEmail = (email) => {
  const validEmailPattern = /^[^\s@]+@(?!aol|excite|fastmail|gandi|gmail|gmx|hey|hotmail|hushmail|icloud|kolab|live|lycos|mail|mailfence|msn|outlook|passport|posteo|protonmail|rackspace|rediffmail|runbox|tutanota|yahoo|yandex|zoho)[^\s@]+\.[^\s@]+$/
  return validEmailPattern.test(email)
}

const isValidPhoneNumber = (phoneNumber) => {
  const phoneNumberDigits = getPhoneNumberDigits(phoneNumber)
  return phoneNumberDigits.length === 10
}

const isValidRequiredField = (field) => {
  return field !== ''
}

const isValidForm = (field, fieldError) => {
  const fieldErrors = Object.keys(fieldError)
  const fields = Object.keys(field)
  const isWithoutFieldErrors = fieldErrors.every(fieldName => fieldError[fieldName] === '') // eslint-disable-line security/detect-object-injection
  const hasAllFieldsFilled = fields.every(fieldName => field[fieldName] !== '') // eslint-disable-line security/detect-object-injection
  return isWithoutFieldErrors && hasAllFieldsFilled
}

const isValidField = ({ event, setFieldError, fieldError, fieldErrorValidators }) => {
  if (event.target.value === '') {
    setFieldError({ ...fieldError, [event.target.name]: '' })
  } else if (!fieldErrorValidators[event.target.name].validator(event.target.value)) {
    setFieldError({ ...fieldError, [event.target.name]: fieldErrorValidators[event.target.name].error })
  } else {
    setFieldError({ ...fieldError, [event.target.name]: '' })
  }
}

const isAllowedToUpgradePlan = (currentUser) => {
  const { isAdmin, isTrialUser } = getTalkItUser(currentUser)
  const { paymentProvider, subscriptionId } = getTalkItTeam(currentUser)

  return paymentProvider !== PAYMENT_PROVIDER.MANUAL && subscriptionId === SUBSCRIPTION.FREE_TRIAL && isAdmin && isTrialUser
}

const isAllowedToUpdatePaymentMethod = (currentUser) => {
  const { isAdmin } = getTalkItUser(currentUser)
  const { paymentProvider, paymentSourceId } = getTalkItTeam(currentUser)

  return paymentProvider !== PAYMENT_PROVIDER.MANUAL && paymentSourceId !== PAYMENT_SOURCE.NONE && isAdmin
}

export {
  isValidForm,
  isValidField,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
  isValidFullName,
  isAllowedToUpgradePlan,
  isAllowedToUpdatePaymentMethod,
  isValidRequiredField
}
