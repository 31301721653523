import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'
import { H3 } from 'shared/components/Text'
import styled from 'styled-components'

export const Section = styled.div`
    height: 150px;
    max-width: 250px;
    width: calc(50% - 2rem);
    margin: 1rem;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0.75rem;
    border: 2px solid ${props => props.color};
    background-color: ${props => props.filled ? props.color : colors.WHITE};
    transition: all .2s ease-in-out; 
    user-select: none;

    &:hover {
        transform: scale(1.025); 
    }

    ${media.tablet} {
        margin: 0.5rem;
        max-width: 50%;
        width: calc(50% - 1rem);
    }
`

export const TitleContainer = styled.div`
    > H3 {
        color: ${props => props.filled ? colors.WHITE : props.color};
        font-weight: bold;
}
`

export const GroupTypeDetail = styled(H3)`
    margin-bottom: 0;
    color: ${colors.WHITE};
    display: flex;
    font-size: 1rem;
    opacity: 0.75;
    margin-top: 0.25rem;
    text-transform: capitalize;

`

export const MenuIconWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`
