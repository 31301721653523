import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const HorizontalScroll = styled.div`
    max-width: 650px;
    overflow: auto;
    border: 2px solid ${colors.GREY_200};
    border-radius: .875rem;
    min-height: 300px;
    margin-bottom: 2rem;
`

export const ListContainer = styled.table`
    width: 100%;
    max-width: 650px;
    height: 100%;
    border-collapse: collapse;
    position: relative; 
    display: flex;
    flex-direction: column;
`

export const ListHeader = styled.thead`
    height: 48px;
    width: 100%;
    display: flex;
`

export const HeaderRow = styled.tr`
    background-color: ${colors.GREY_100};
    height: 58px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
`

export const ListBody = styled.tbody`
    width: 100%;
    background: ${colors.WHITE};
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
`

const baseColumnHeader = css`
    border-color: ${colors.GREY_200};
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
    color: ${colors.GREY_700};
    text-align: left;
    padding-left: 15px;
    position: sticky;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

export const ColumnHeader = styled.th`
    ${baseColumnHeader}

    &:first-child {
        max-width: 120px;
        border-top-left-radius: 10px;
    }
    &:nth-child(2) {
        max-width: 170px;
    }
    &:last-child {
        border-top-right-radius: 10px;
        justify-content: flex-end;
        padding-left: 0;
        padding-right: 15px;
        max-width: 125px;
    }
`

export const QTYHeader = styled.th`
    ${baseColumnHeader}
`
