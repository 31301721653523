import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './shared/serviceWorker'
import { setupLogger } from './shared/logger'
import setupAwsAmplify from './shared/aws'

function setupApp () {
  setupLogger()
  setupAwsAmplify()

  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.unregister()
}

export default setupApp
