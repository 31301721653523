import styled from 'styled-components'
import { colors } from 'shared/colors'

export const ItemRow = styled.tr`
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${colors.BLACK};
`

export const PaidStatusColumn = styled.td`
    width: 100%;
    max-width: 120px;
    padding-left: 15px;
    color: ${colors.GREEN};
`

export const UnpaidStatusColumn = styled.td`
    width: 100%;
    max-width: 120px;
    padding-left: 15px;
    color: ${colors.RED}
`

export const DateColumn = styled.td`
    width: 100%;
    max-width: 170px;
    padding-left: 15px;
`

export const QuantityColumn = styled.td`
    width: 100%;
    max-width: 85px;
    padding-left: 15px;
`

export const AmountColumn = styled.td`
    width: 100%;
    padding-left: 15px;
`

export const ViewColumn = styled.td`
    width: 100%;
    max-width: 125px;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
`

export const ViewButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    border-radius: 2rem;
    transition: ease .25s;

    &:hover {
        background: ${colors.GREY_200};

    }
`
