import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const Section = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    max-width: 48.875rem;
    padding: 0 0 1.875rem;

    ${media.mobileNav} {
        padding: 0;
    }

    @media (max-width: 930px) {
        flex-direction: column;
    }
`

export const Column = styled.div`
    max-width: 23.5rem;
    width: cacl(100% - 1.875rem);

    @media (max-width: 1030px) {
        max-width: calc(50% - .9rem);
    }
    @media (max-width: 930px) {
        max-width: 100%;
    }
`

export const SubSection = styled.div`
    margin-bottom: 1.875rem;

    &:last-child {
        margin-bottom: 1rem;
    }
`

export const RecipientList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

export const RecipientClickArea = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: .9rem;
`

export const EmptyState = styled.div`
    padding: .75rem;
    color: ${colors.GREY}
`
