import React from 'react'
import { StyledBanner, Message, Action, Title, StyledIcon } from './index.styles'

function Banner (props) {
  return (
    <StyledBanner {...props}>
      <Message>
        {props.icon && <StyledIcon color={props.iconColor} path={props.icon} size={1} />}
        <Title>{props.title}</Title>
      </Message>
      <Action>{props.action}</Action>
    </StyledBanner>
  )
}

export default Banner
