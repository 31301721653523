import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const File = styled.div`
    display: flex;
    width: 100%;
    background-color: ${colors.WHITE};
    border-radius: 5px;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
`

export const FileTitle = styled.div`
    user-select: none;
`

export const DragAndDropWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    outline: none;
    cursor: pointer;
`

export const DropFileArea = styled.div`
    border: 2px dashed ${colors.GREY_100};
    height: 150px;
    border-radius: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        border-color: ${colors.BLUE};
    }

    ${props => props.isDragActive && css`
        border-color: ${colors.BLUE};
    `}
`

export const PlaceholderText = styled.div`
    text-align: center;

    p:last-of-type {
        color: ${colors.GREY};
    }
`
