import styled, { css } from 'styled-components'
import { colors } from 'shared/colors'

export const MessageWrapper = styled.div`
    padding: 1rem;
    background: ${colors.GREY_200};
    border-radius: .95rem;
    color: ${colors.BLACK};

    ${props => props.success && css`
        background: ${colors.GREEN};
        color: ${colors.BLACK};
    `}
`
