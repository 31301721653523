import React, { useState } from 'react'
import Icon from '@mdi/react'
import { StyledInput, StyledMaskInput, RevealContainer, RevealAction, Label, Error, ErrorContainer } from './index.styles'
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { colors } from 'shared/colors'

function Input ({ label, error, mask, children, type, ...props }) {
  const [revealed, setRevealed] = useState(false)

  const withLabel = (component) => {
    if (label) {
      return <Label>{label}{component}</Label>
    }

    return component
  }

  const withError = (component) => {
    return (
      <ErrorContainer>
        {component}
        {error && <Error>{error}</Error>}
      </ErrorContainer>
    )
  }

  const withReveal = (component) => {
    const toggleReveal = () => {
      setRevealed(!revealed)
    }

    return (
      <RevealContainer>
        {component}
        {!revealed && <RevealAction onClick={toggleReveal}><Icon color={colors.BLACK} data-testid={'show-password'} path={mdiEye} size={1}/></RevealAction>}
        {revealed && <RevealAction onClick={toggleReveal}><Icon color={colors.BLACK} data-testid={'hide-password'} path={mdiEyeOff} size={1}/></RevealAction>}
      </RevealContainer>
    )
  }

  if (type === 'password') {
    const revealedType = revealed ? 'text' : 'password'
    return withLabel(withError(withReveal(<StyledInput error={error} type={revealedType} {...props} />)))
  }

  if (mask) {
    return withLabel(withError(<StyledMaskInput error={error} mask={mask} {...props} type={type} />))
  }

  return withLabel(withError(<StyledInput error={error} {...props} type={type} />))
}

export default Input
