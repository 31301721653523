import React from 'react'
import Icon from '@mdi/react'
import { mdiClockOutline, mdiFaceAgent } from '@mdi/js'
import { PendingNoteContainer } from './index.styles'
import { H1, P2 } from 'shared/components/Text'
import { Button } from 'shared/components/Button'
import { useModalContext } from 'shared/context/ModalProvider'
import HelpModal from 'shared/components/HelpModal'
import { formatStandardDateTime } from 'shared/formatters'
import { colors } from 'shared/colors'

function PendingNote (props) {
  const { openModal } = useModalContext()

  const helpModalEmailBody = `Hello, I’m contacting you to inquire about my pending note${props.job_id ? ` (job #${props.job_id})` : ''} submitted on ${formatStandardDateTime(props.created_on)}`

  return (
    <PendingNoteContainer {...props}>
      <Icon color={colors.BLUE} path={mdiClockOutline} size={3}></Icon>
      <H1>Note is pending</H1>
      <P2>Submitted on {formatStandardDateTime(props.created_on)} {props.job_id && <span>• Job #{props.job_id}</span>}</P2>
      <Button data-testid="need-help-button" iconColor={colors.WHITE} iconName={mdiFaceAgent} onClick={() => openModal(<HelpModal emailBody={helpModalEmailBody} />)}>Need Help?</Button>
    </PendingNoteContainer>
  )
}

export default PendingNote
