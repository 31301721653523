import React from 'react'
import { Button } from '../Button'
import { ModalDescription } from '../Text/index'
import { mdiEmailOutline, mdiPhone } from '@mdi/js'
import { useModalContext } from 'shared/context/ModalProvider'
import Modal from '../Modal'
import { log } from 'shared/logger'
import events from 'shared/events'
import { colors } from 'shared/colors'

const HelpModal = (props) => {
  const { closeModal } = useModalContext()

  const emailBody = props.emailBody ? `?body=${props.emailBody}` : ''

  return (
    <Modal onClose={closeModal} title={'Need Help?'} {...props}>
      <ModalDescription>Give us a call during our business hours (9am-5pm CST), or leave us an email and we’ll get back to you right away.</ModalDescription>
      <Button data-testid='email' href={`mailto:support@mobileassistant.us${emailBody}`} iconColor={colors.WHITE} iconName={mdiEmailOutline} onClick={() => log(events.SUPPORT_EMAILED) }>support@mobileassistant.us</Button>
      <Button data-testid='phone' href={'tel:888-373-1916'} iconColor={colors.WHITE} iconName={mdiPhone} onClick={() => log(events.SUPPORT_CALLED) }>(888) 373-1916</Button>
    </Modal>
  )
}

export default HelpModal
