import env from './envConfig'

const trackPageview = () => {
  if (window.ga) {
    window.ga('create', env.GOOGLE_ANALYTICS_API_KEY, 'auto')
    window.ga('set', 'page', window.location.pathname)
    window.ga('send', 'pageview')
  }
}

export {
  trackPageview
}
