const PAYMENT_PROVIDER = {
  NONE: 'none',
  MANUAL: 'manual',
  AUTHORIZE_NET: 'authorize_net'
}

const NOTIFICATION_TYPE = {
  NONE: 'none',
  ALL: 'all',
  ATTACHMENT: 'email_attachment',
  BODY: 'email_body'
}

const SUBSCRIPTION = {
  FREE_TRIAL: 1
}

const PAYMENT_SOURCE = {
  NONE: 0
}

export {
  PAYMENT_PROVIDER,
  NOTIFICATION_TYPE,
  SUBSCRIPTION,
  PAYMENT_SOURCE
}
