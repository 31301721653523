import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const SidebarWrapper = styled.div`
    height: 100%;
    max-width: 225px;
    z-index: 999;

    ${media.tablet} {
        height: 4rem;
        max-width: 100%;
        width: 100%;
        bottom: 0;
        position: fixed;
        z-index: 500;
    }

    nav {
        font-family: 'Nunito Sans',sans-serif;
        font-family: Nunito Sans;
        font-style: normal;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: ${colors.WHITE};
        border-right: 2px solid ${colors.GREY_200};
        justify-content: space-between;
        align-items: flex-start;

        ${media.tablet} {
            border-right: none;
            flex-direction: row;
            border-top: 2px solid ${colors.GREY_200};
            justify-content: center;
            height: 4rem;
            width: 100vw;
        }
    }

    nav ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 2rem;

        ${media.tablet} {
            margin: 0;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-evenly;

        }

        li {
            display: flex;
            margin: 0;
            transition: .25s ease;

            ${media.tablet} {
                margin: 0 .5rem;
            }
        }

        li a {
            opacity: .5;

            :hover {
                opacity: 1;
            }
            
            ${media.tablet} {
                flex-direction: column;
                font-size: .7rem;
                background-color: none;
                width: 4rem;
                padding: .3rem;
                width: 3.5rem;
                height: 3.5rem;

                svg {
                    margin: 0;
                    margin-bottom: .1rem;
                }
                
                span {
                    margin-left: 0 !important;
                }

                :hover {
                    background: none;
                }
            }
        }
    }

    .toggleButton {
        ${media.tablet} {
            display: none;
        }
    }

    .minimizeButton {
        display: flex;
        flex-flow: row;
        width: max-content;

        :hover {
            box-shadow: 0 2px 7px ${colors.BLACK_A40};
            opacity: 1;

            svg {
                margin-right: .3rem;
            }
        }

        ${media.tablet} {
            display: none;
        }

        :hover {
            ::after {
                content: 'Expand Menu'
            }
        }
    }

    .ExpandedNav {
        padding-right: 2rem;

        @media (max-width: 768px) {
            padding-right: 1rem;
        }
        ${media.print} {
            display: none;
        }
    }

    .MinimizedNav {
        width: calc(53px + 2rem - 2px);

        ${media.tablet} {
            width: 100vw;
        }

        .talkItTitle {
            a {
                padding: 0;

                h1 {
                    display: none;
                }
            }
        }
    }
`

export const TalkItTitle = styled.div`
    display: flex;
            
    ${media.tablet} {
        display: none;
    }

    a {
        transition: padding 0.2s ease;
        text-decoration: none;
        display: inline-flex;
        margin: 0;
        padding: 1rem;
        padding-top: 0.5rem;

        h1 {
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 105%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 0 0 0 .5rem;
            color: ${colors.BLUE};
        }

        span {
            font-weight: normal;
            font-size: .9rem;
            color: ${colors.GREY};
        }
    }
`

export const NavItem = styled.li`
    .active {
        opacity: 1;
    }

    .expandedButton {
        ${media.minTablet} {
            padding: 1.5rem 1rem;
            margin: 0.25rem 0;

            span {
                font-size: 1.15rem;
            }
        }

        ${media.desktop} {
            padding: 1.5rem 1rem;
            margin: 0.25rem 0;

            span {
                font-size: 1.15rem;
            }
        }
    }

    .iconButton {
        justify-content: flex-start;
        margin: 0.25rem 0;

        svg {
            margin: 0;
        }

        ${media.tablet} {
            flex-direction: column;
            font-size: .7rem;
            background-color: none;
            width: 4rem;

            ::after {
                content: "${props => props.title}"
            }

            svg {
                margin: 0;
                margin-bottom: .1rem;
            }
        }

        :hover {
            background: ${colors.GREY_100};
            box-shadow: 0 2px 7px ${colors.BLACK_A40};
            opacity: 1;

            ::after {
                content: "${props => props.title}"
            }

            ${media.desktop} {
                ::after {
                    font-size: 1.15rem;
                    content: "${props => props.title}"
                }
            }

            svg {
                margin-right: .3rem;
            }

            ${media.tablet} {
                flex-direction: column;
                font-size: .7rem;
                background-color: none;
                width: 4rem;
                background: none;
                color: ${colors.BLACK};
                box-shadow: 0 0 0 ${colors.TRANSPARENT};

                svg {
                    margin: 0;
                    margin-bottom: .1rem;
                    path {
                        fill: ${colors.BLACK}; !important;
                    }
                }

                :hover {
                    svg {
                        path {
                            fill: ${colors.BLACK} !important;
                        }
                    } 
                }
            }
        }
    }
`
