import React from 'react'
import { ModalBackground, ModalContainer, ModalTitle, TitleWrapper, ModalBody, ModalDialogue, ModalBackgroundContainer } from './index.styles'
import { Button } from '../Button'
import { mdiClose } from '@mdi/js'
import { colors } from 'shared/colors'

function Modal (props) {
  const {
    secondary: dialogueSecondaryAction = {},
    primary: dialoguePrimaryAction = {}
  } = props.dialogue || {}

  return (
    <ModalBackground>
      <ModalBackgroundContainer>
        <ModalContainer {...props}>
          <TitleWrapper>
            <ModalTitle>{props.title}</ModalTitle>
            {props.onClose && <Button data-testid="close-modal" iconColor={colors.BLACK} iconName={mdiClose} onClick={props.onClose} small text />}
          </TitleWrapper>
          <ModalBody>{props.children}</ModalBody>
          {props.dialogue &&
          <ModalDialogue>
            {props.dialogue.secondary && <Button {...dialogueSecondaryAction} data-testid="dialogue-secondary-action" inverse>{dialogueSecondaryAction.children || 'Cancel'}</Button>}
            <Button {...dialoguePrimaryAction} data-testid="dialogue-primary-action">{dialoguePrimaryAction.children || 'Save'}</Button>
          </ModalDialogue>
          }
        </ModalContainer>
      </ModalBackgroundContainer>
    </ModalBackground>
  )
}

Modal.defaultProps = {
  secondaryButtonText: 'Cancel',
  actionButtonText: 'Save'
}

export default Modal
