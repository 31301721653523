import styled from 'styled-components'
import { colors } from 'shared/colors'

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    width: inherit;
`
export const Square = styled.div`
    background: ${colors.PURPLE};
    width: ${props => props.small ? '2.85rem' : '3.13rem'};
    height: ${props => props.small ? '2.85rem' : '3.13rem'};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        color: ${colors.WHITE};
        margin: 0;
    }
`

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin: .13rem 0 0 .5rem;
    width: calc(100% - 6.87rem);
    min-width: 0;

    h3 {
        color: ${colors.BLACK};
        height: 1.26rem;
        margin-bottom: 0;
        WHITE-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: .1rem;
    }

    p {
        color: ${colors.GREY_700};
        height: 1.1rem;
        line-height: 1.1rem;
        padding-left: .1rem;
        WHITE-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
