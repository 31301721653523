import styled from 'styled-components'
import { colors } from 'shared/colors'

export const Img = styled.img`
    width: 350px;
    height: auto;
`

export const InvoiceEmptyState = styled.td`
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: ${colors.GREY_700};
`
