import React from 'react'
import { mdiInformation } from '@mdi/js'
import { IntegrationItems, ItemsList, DisclaimerText } from './index.styles'
import { getCrmFromUser } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'
import AlertMessage from 'shared/components/AlertMessage'
import IntegrationItem from 'shared/components/IntegrationsItem'
import { CRMS_AVAILABLE } from 'shared/crmIntegrations'
import AccountPageLayout from 'shared/components/AccountPageLayout'
import { P2 } from 'shared/components/Text'

function AccountIntegrations () {
  const { currentUser } = useAuthContext()
  const userCrm = getCrmFromUser(currentUser)
  const crmListSorted = CRMS_AVAILABLE.sort((a, b) => (a.title.toUpperCase() > b.title.toUpperCase()) ? 1 : -1)

  const isInstalled = (crmName) => {
    return (crmName === userCrm)
  }

  return (
    <AccountPageLayout>
      <ItemsList>
        { crmListSorted.map((item, index) => (
          <IntegrationItems key={index}>
            <IntegrationItem guideLink={item.quickStartGuide} imagePath={item.imagePath} installStatus={isInstalled(item.crmName)} title={item.title}>{item.description}</IntegrationItem>
          </IntegrationItems>
        ))}
      </ItemsList>
      <DisclaimerText>
        <AlertMessage iconName={mdiInformation}><P2>Mobile Assistant integrates with these CRM partners. Please contact us by using the “need help” button to integrate with your preferred CRM.</P2></AlertMessage>
      </DisclaimerText>
    </AccountPageLayout>
  )
}

export default AccountIntegrations
