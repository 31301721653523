import React from 'react'
import { Container, Row, Title, Data } from './index.styles'

function Metadata (props) {
  const { items } = props

  return (
    <Container>
      {items && items.length && items.map((item, index) => (
        <Row key={index}>
          <Title>{item.title}</Title>
          <Data>{item.data}</Data>
        </Row>
      ))}
    </Container>
  )
}

export default Metadata
