import { createGlobalStyle } from 'styled-components'
import { colors } from '../shared/colors'

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: 'Nunito Sans', sans-serif
    }

    body {
        padding: 0;
        margin: 0;
    }

    body, html, #root {
        background-color: ${colors.WHITE};
        height: 100%;
    }

    #root {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    a, a:visited {
        color: ${colors.BLUE};
        text-decoration: none;
    }

    a:hover {
        color: ${colors.BLUE_200};
    }
`
