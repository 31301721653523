const text = {
  INFO_TEXT: {
    dictatorID: 'You can call and leave notes to the Dictation Hotline from any phone. If calling from an unregistered phone, please have your dictator ID ready to enter when prompted.',
    registeredNumber: 'Register your phone numbers to automatically be recognized and avoid entering a dictator ID when you call our hotline.',
    defaultNote: 'Default note recipients receive a copy of every note. These recipients might be assistants or co-workers. To prevent a note from going to this list, at the beginning of your dictation say, "Send this note to (desired recipients)."',
    optionalNote: 'Optional note recipients can receive copies of your notes as well. Specify which recipient(s) should receive a copy by saying, "Send John a copy of this”, at the beginning of a note.',
    shareToTeam: 'When selected, this template will be made available to your fellow team members.'
  },
  USER_PHONE: {
    CREATE: {
      SUCCESS: 'Success, phone number was added',
      FAILED: 'Adding failed, please try again',
      FAILED_ALREADY_REGISTERED: 'Adding failed because phone number is already registered'
    },
    UPDATE: {
      SUCCESS: 'Success, phone number was updated',
      FAILED: 'Updating failed, please try again',
      FAILED_ALREADY_REGISTERED: 'Updating failed because phone number is already registered'
    },
    DELETE: {
      SUCCESS: 'Success, phone number was deleted',
      FAILED: 'Deleting failed, please try again'
    }
  },
  USER: {
    UPDATE: {
      SUCCESS: 'Success, user was updated',
      FAILED: 'Updating failed, please try again',
      FAILED_ALREADY_REGISTERED: 'Updating failed because user phone or user email is already registered',
      EMAIL_IN_USE: 'Updating failed because this email is already in use'
    }
  },
  PAYMENT: {
    CREATE: {
      SUCCESS: 'Success, your subscription started!',
      FAILED: 'Starting subscription failed, please try again'
    },
    UPDATE: {
      SUCCESS: 'Success, payment method updated',
      FAILED: 'Updating failed, please try again'
    }
  },
  SUBSCRIPTION_REQUEST: {
    CREATE: {
      SUCCESS: 'Success, request was submitted',
      FAILED: 'Request failed, please try again'
    }
  }
}

export {
  text
}
