import React, { createContext, useState, useContext } from 'react'
import ReactDOM from 'react-dom'

export const ModalContext = createContext(null)

export const ModalContainer = () => {
  const { modal } = useModalContext()
  return modal ? ReactDOM.createPortal(modal, document.body) : null
}

const ModalProvider = (props) => {
  const [modal, setModal] = useState(null)

  const closeModal = () => setModal(null)
  const openModal = modal => setModal(modal)

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal
      }}
      {...props} />
  )
}

export const useModalContext = () => useContext(ModalContext)

export default ModalProvider
