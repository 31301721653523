const CRMS_AVAILABLE = [
  {
    title: 'Redtail',
    imagePath: 'https://s3.amazonaws.com/assets.mobileassistant.us/talk-it/redtail.png',
    description: 'Redtail can be integrated with Mobile Assistant.',
    crmName: 'redtail',
    quickStartGuide: 'https://mobileassistant.us/wp-content/uploads/2019/07/Quickstart-Guide-Redtail.pdf'
  },
  {
    title: 'Salesforce',
    imagePath: 'https://s3.amazonaws.com/assets.mobileassistant.us/talk-it/salesforce.png',
    description: 'Salesforce can be integrated with Mobile Assistant.',
    crmName: 'salesforce',
    quickStartGuide: 'https://mobileassistant.us/wp-content/uploads/2019/07/Quickstart-Guide-Salesforce-Task.pdf'
  },
  {
    title: 'Smart Office',
    imagePath: 'https://s3.amazonaws.com/assets.mobileassistant.us/talk-it/smart_office.png',
    description: 'Smart Office can be integrated with Mobile Assistant.',
    crmName: 'smart_office',
    quickStartGuide: 'https://mobileassistant.us/wp-content/uploads/2019/07/Quickstart-Guide-SmartOffice.pdf'
  },
  {
    title: 'Wealthbox',
    imagePath: 'https://s3.amazonaws.com/assets.mobileassistant.us/talk-it/wealthbox.png',
    description: 'Wealthbox can be integrated with Mobile Assistant.',
    crmName: 'wealthbox',
    quickStartGuide: 'https://mobileassistant.us/wp-content/uploads/2019/07/Quickstart-Guide-Wealthbox.pdf'
  }
]

export {
  CRMS_AVAILABLE
}
