import * as Sentry from '@sentry/browser'
import amplitude from 'amplitude-js'
import env, { isNotRunningLocally } from './envConfig'
import events from './events'

const log = (event) => {
  if (isNotRunningLocally()) {
    Sentry.addBreadcrumb(event)
    amplitude.getInstance().logEvent(event)
  } else {
    console.log(event) // eslint-disable-line
  }
}

const setupLogger = () => {
  if (isNotRunningLocally()) {
    Sentry.init({ dsn: env.SENTRY.DSN })
    amplitude.init(env.AMPLITUDE.API_KEY)
  }
}

const logUserSignedIn = (user = {}) => {
  log(events.USER_LOGGED_IN)
  if (isNotRunningLocally()) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: user.id })
    })
    amplitude.getInstance().setUserId(user.id)
  }
}

const logUserSignedOut = () => {
  log(events.USER_SIGNED_OUT)
  if (isNotRunningLocally()) {
    amplitude.getInstance().clearUserProperties()
  }
}

export {
  log,
  logUserSignedIn,
  logUserSignedOut,
  setupLogger
}
