const isTalkItAdmin = (user) => {
  return user.signInUserSession.idToken?.payload['cognito:groups']?.includes('TalkItAdmin') ?? false
}

const canEditTemplate = (template, user) => {
  const talkItUser = user.talkItAttributes.user
  return template.user_id === talkItUser.id ||
  (template.group_id === talkItUser.group?.team_group_id && talkItUser.group?.is_team_group_admin) ||
  (template.group_id === talkItUser.group?.org_group_id && talkItUser.group?.is_org_group_admin) ||
  (template.group_id === 1 && isTalkItAdmin(user))
}

const canShareToTeam = (template, user) => {
  const talkItUser = user.talkItAttributes.user
  return (template.user_id === talkItUser.id ||
    (template.group_id === talkItUser.group?.team_group_id && talkItUser.group?.is_team_group_admin)) && talkItUser.group?.is_team_group_admin
}

const canShareToOrg = (template, user) => {
  const talkItUser = user.talkItAttributes.user
  return (template.user_id === talkItUser.id ||
    (template.group_id === talkItUser.group?.org_group_id && talkItUser.group?.is_org_group_admin)) && talkItUser.group?.is_org_group_admin
}

const canShareToEveryone = (template, user) => {
  const talkItUser = user.talkItAttributes.user
  return (template.user_id === talkItUser.id || (template.group_id === 1 && isTalkItAdmin(user))) && isTalkItAdmin(user)
}

export {
  canEditTemplate,
  canShareToTeam,
  canShareToOrg,
  canShareToEveryone
}
