import React, { useState } from 'react'
import { H1 } from 'shared/components/Text'
import Input from 'shared/components/Input'
import { isValidEmail } from 'shared/validators'
import { getEmailFromLocationState } from 'shared/extractors'
import { Button, LinkButton } from 'shared/components/Button'
import { ForgotPasswordContainer, InputContainer, LogoWrapper } from './index.styles'
import { ReactComponent as Logo } from 'shared/assets/welcome-logo.svg'

import LoadingSpinner from 'shared/components/LoadingSpinner'
import { Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useNotificationContext } from 'shared/context/NotificationProvider'

function ForgotPassword (props) {
  const initialFieldState = { email: getEmailFromLocationState(props) }
  const [field, setField] = useState(initialFieldState)
  const [loading, setLoading] = useState(false)
  const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false)
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const requestPasswordRecovery = async () => {
    if (isValidEmail(field.email)) {
      setLoading(true)

      try {
        await Auth.forgotPassword(field.email)
        setLoading(false)
        sendSuccessNotification({ title: 'Email has been sent!' })
        setForgotPasswordEmailSent(true)
      } catch (error) {
        setLoading(false)

        if (error.code === 'LimitExceededException') {
          sendErrorNotification({ title: 'Password reset limit reached, please try again in a couple hours.' })
        } else {
          sendSuccessNotification({ title: 'Email has been sent!' })
          setForgotPasswordEmailSent(true)
        }
      }
    }
  }

  if (forgotPasswordEmailSent) {
    return <Redirect to="/signin" />
  }

  return (
    <ForgotPasswordContainer>
      <LogoWrapper>
        <Logo alt="Mobile Assistant's Talk It Online Logo"/>
      </LogoWrapper>
      <H1 center>Forgot Password</H1>
      <InputContainer>
        <Input
          label="Account Email"
          name="email"
          onChange={updateField}
          placeholder="What's your email?"
          type="email"
          value={field.email} />
        <Button disabled={!isValidEmail(field.email)} onClick={requestPasswordRecovery}>
          {!loading && <span>Send Recovery Email</span>}
          {loading && <LoadingSpinner />}
        </Button>
        <LinkButton id="signin" to={{ pathname: '/signin', state: { email: field.email } }} text>Back to Sign In</LinkButton>
      </InputContainer>
    </ForgotPasswordContainer>
  )
}

export default ForgotPassword
