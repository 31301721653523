import { Container, Footer, Footnote, Plan, PlanTitle, PlanGroup, PlanHeader, Price, SubscriptionFeature, SubscriptionFeatureBold, SubscriptionUpgradeButton, TitleContainer, Title } from './index.styles'
import React from 'react'
import { H1, H2 } from '../Text'
import SubscriptionPickerModal from 'shared/components/SubscriptionPickerModal'
import { useModalContext } from 'shared/context/ModalProvider'

function SubscriptionPicker ({ subscriptions, upgradeButtonText }) {
  const { openModal } = useModalContext()

  return (
    <Container>
      <PlanHeader>
        <H2 style={{ margin: 0 }}>PRICING</H2>
        <H1 style={{ margin: 0 }}>Subscribe Today!</H1>
      </PlanHeader>
      <PlanGroup>
        {subscriptions?.length > 0 && subscriptions.map((subscription) =>
          <Plan color={subscription.bg_color} key={subscription.id}>
            <div>
              <PlanTitle>{subscription.name.toUpperCase()}</PlanTitle>
              <Price>${subscription.price} / {subscription.price_duration_type}</Price>
              <TitleContainer>
                <Title><del>${subscription.price_strikethrough_text}</del> {subscription.price_discounted_text}</Title>
                <Title>{subscription.tag_line}</Title>
              </TitleContainer>
              {subscription.bold_text_list.map((text, index) =>
                <SubscriptionFeatureBold key={index}>{text}</SubscriptionFeatureBold>
              )}
              {subscription.non_bold_text_list.map((text, index) =>
                <SubscriptionFeature key={index}>{text}</SubscriptionFeature>
              )}
              <Footnote>{subscription.disclaimer_text}</Footnote>
            </div>
          </Plan>
        )}
      </PlanGroup>
      <SubscriptionUpgradeButton onClick={() => openModal(<SubscriptionPickerModal subscriptions={subscriptions} upgradeButtonText={upgradeButtonText} />)}>{upgradeButtonText}</SubscriptionUpgradeButton>
      <Footer />
    </Container>
  )
}

export default SubscriptionPicker
