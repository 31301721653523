import React, { useState, useEffect, useCallback } from 'react'
import { API } from 'aws-amplify'
import { Img, InvoiceEmptyState } from './index.styles'
import noInvoices from 'shared/assets/no-invoices.png'
import { H3 } from 'shared/components/Text'
import InvoiceItem from 'shared/components/InvoiceItem'
import InvoiceList from 'shared/components/InvoiceList'
import { useAuthContext } from 'shared/context/AuthProvider'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import AccountPageLayout from 'shared/components/AccountPageLayout'
import UpdateBillingButton from 'shared/components/UpdateBillingButton'
import { getTalkItUser } from 'shared/extractors'

function AccountBilling () {
  const [invoiceInfo, setInvoiceInfo] = useState([])
  const [invoiceLoading, setInvoiceLoading] = useState(true)
  const { currentUser } = useAuthContext()
  const { teamId, cognitoId } = getTalkItUser(currentUser)

  const fetchInternalInvoiceInfo = useCallback(async () => {
    setInvoiceLoading(true)
    setInvoiceInfo(await fetchInvoiceInfo(cognitoId, teamId))
    setInvoiceLoading(false)
  }, [cognitoId, teamId])

  const fetchInvoiceInfo = async (cognitoId, teamId) => {
    if (!cognitoId) {
      return null
    }

    try {
      const results = (await API.get('TALK_IT_API', `/team/${teamId}/invoice?cognito_id=${cognitoId}`)).data
      return results
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    fetchInternalInvoiceInfo()
  }, [fetchInternalInvoiceInfo])

  return (
    <AccountPageLayout>
      <UpdateBillingButton />
      <InvoiceList showQuantity={invoiceInfo && invoiceInfo.TotalUsers > 1}>
        {!invoiceLoading
          ? invoiceInfo && invoiceInfo.InvoiceLog
            ? invoiceInfo.InvoiceLog.map((logItem, index) => <InvoiceItem cognitoId={cognitoId} key={index} logItem={logItem} showQuantity={invoiceInfo.TotalUsers > 1} teamId={teamId}></InvoiceItem>)
            : (<tr><InvoiceEmptyState colSpan={4} data-testid="noInvoices"><Img alt="Graphic showing no invoices yet." src={noInvoices}></Img><H3>You don't seem to have any invoices.</H3></InvoiceEmptyState></tr>)
          : (<tr><td><LoadingSpinner space/></td></tr>)}
      </InvoiceList>
    </AccountPageLayout>
  )
}

export default AccountBilling
