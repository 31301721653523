const postAccountPayload = (account) => {
  return {
    first_name: account.firstName,
    last_name: account.lastName,
    email: account.email,
    password: account.password,
    phone_number: account.phoneNumber,
    coupon_code: 'TrialUsers',
    ip_address: account.ipAddress,
    captcha_token: account.captchaToken
  }
}

export {
  postAccountPayload
}
