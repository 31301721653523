import React from 'react'
import { EmptyNoteContainer, EmptyIllustration } from './index.styles'

function EmptyNote (props) {
  return (
    <EmptyNoteContainer {...props}>
      <EmptyIllustration />
      Note taking should be simple.
    </EmptyNoteContainer>
  )
}

export default EmptyNote
