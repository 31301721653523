import React, { useEffect, useState } from 'react'

import LoadingSpinner from '../../shared/components/LoadingSpinner'
import { Redirect } from 'react-router-dom'
import events from 'shared/events'
import { log } from 'shared/logger'
import { useAuthContext } from '../../shared/context/AuthProvider'

function AccountPassthrough (props) {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const search = props.location.search
  const { reloadUser } = useAuthContext()

  useEffect(() => {
    const loadUser = async () => { await reloadUser() }

    const link = decodeURIComponent(new URLSearchParams(search).get('link'))
    const prefix = decodeURIComponent(new URLSearchParams(search).get('prefix'))
    const username = decodeURIComponent(new URLSearchParams(search).get('username'))
    const accessToken = decodeURIComponent(new URLSearchParams(search).get('at'))
    const idToken = decodeURIComponent(new URLSearchParams(search).get('it'))

    const currentRedirect = localStorage.getItem('passthroughLink')

    localStorage.clear()
    localStorage.setItem('passthroughLink', link)
    localStorage.setItem(`${prefix}.LastAuthUser`, username)
    localStorage.setItem(`${prefix}.${username}.idToken`, idToken)
    localStorage.setItem(`${prefix}.${username}.accessToken`, accessToken)
    // Note: reloading the user will cause this component to reload if the user changes, this is intended
    loadUser()

    if (currentRedirect) {
      localStorage.removeItem('passthroughLink')
      log(events.USER_PASSTHROUGH_AUTH)
      setRedirectUrl('/' + currentRedirect)
    } else if (props.isLoggedIn) {
      localStorage.removeItem('passthroughLink')
      log(events.USER_PASSTHROUGH_AUTH)
      setRedirectUrl('/' + link)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirectUrl) {
    return (<Redirect to={redirectUrl} />)
  }
  return (<LoadingSpinner space />)
}

export default AccountPassthrough
