import moment from 'moment-timezone'

const formatLowercase = (string = '') => {
  return string.toLowerCase()
}

const formatPhoneNumber = (number) => {
  if (!number) return ''
  const clean = number.replace(/\D/g, '')
  const match = clean.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return number
}

const formatContactName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  } else if (firstName) {
    return firstName
  }
  return lastName
}

const formatStandardDate = date => {
  return moment.utc(date).local().format('LL')
}

const formatStandardDateTime = date => {
  return moment.utc(date).tz(moment.tz.guess()).format('LL h:mma z')
}

const formatNoteEmailBody = (content, metadataItems) => {
  const formatMetadatItemsToString = (metadataItems) => {
    return metadataItems.map(item => `${item.title}\n${item.data}\n`).join('\n')
  }

  return `${content}\n\n${formatMetadatItemsToString(metadataItems)}\n`
}

export {
  formatPhoneNumber,
  formatStandardDate,
  formatStandardDateTime,
  formatNoteEmailBody,
  formatLowercase,
  formatContactName
}
