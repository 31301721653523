import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const ForgotPasswordContainer = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem;
    color: ${colors.BLACK};

    h1 {
        width: 100%;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        margin-bottom: 3rem;
    }

    ${media.phone} {
        min-height: 100vh;

        h1 {
            margin-top: 3rem;
            font-size: 2rem;
        }
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
        margin-top: .5rem
    }

    a:last-child {
        margin-top: 1.5rem
    }
`
