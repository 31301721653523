import {
  AnchorLink,
  Error,
  H1
} from 'shared/components/Text'
import { Button, LinkButton } from 'shared/components/Button'
import { InputContainer, LogoWrapper, SignUpContainer } from './index.styles'
import React, { useRef, useState } from 'react'
import {
  getEmailFromLocationState,
  getNameParts,
  getPhoneNumberDigits
} from 'shared/extractors'
import {
  isValidEmail,
  isValidFullName,
  isValidPassword,
  isValidPhoneNumber
} from 'shared/validators'

import { API } from 'aws-amplify'
import Input from 'shared/components/Input'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { ReactComponent as Logo } from 'shared/assets/welcome-logo.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import env from 'shared/envConfig'
import events from 'shared/events'
import { log } from 'shared/logger'
import { postAccountPayload } from 'shared/payloads'

function SignUp (props) {
  const initialFieldState = { email: getEmailFromLocationState(props), password: '', phoneNumber: '', name: '' }
  const initialFieldErrorState = { email: '', password: '', phoneNumber: '', name: '' }
  const [field, setField] = useState(initialFieldState)
  const [fieldError, setFieldError] = useState(initialFieldErrorState)
  const [loading, setLoading] = useState(false)
  const [signedUp, setSignedUp] = useState(false)
  const [signedUpError, setSignedUpError] = useState(false)
  const recaptchaRef = useRef()

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const validateFieldOnKeyUp = (event) => {
    if (fieldError[event.target.name]) {
      validateField(event)
    }
  }

  const validateField = (event) => {
    if (event.target.value === '') {
      setFieldError({ ...fieldError, [event.target.name]: '' })
    } else if (!fieldErrorValidators[event.target.name].validator(event.target.value)) {
      setFieldError({ ...fieldError, [event.target.name]: fieldErrorValidators[event.target.name].error })
    } else {
      setFieldError({ ...fieldError, [event.target.name]: '' })
    }
  }

  const isFormValid = () => {
    const fieldErrors = Object.keys(fieldError)
    const fields = Object.keys(field)
    const isWithoutFieldErrors = fieldErrors.every(fieldName => fieldError[fieldName] === '') // eslint-disable-line security/detect-object-injection
    const hasAllFieldsFilled = fields.every(fieldName => field[fieldName] !== '') // eslint-disable-line security/detect-object-injection
    return isWithoutFieldErrors && hasAllFieldsFilled
  }

  const fieldErrorValidators = {
    email: {
      error: 'Invalid email, please use your best work email',
      validator: isValidEmail
    },
    name: {
      error: 'Invalid full name, please use your full name',
      validator: isValidFullName
    },
    password: {
      error: 'Invalid password, must be 7 characters or longer',
      validator: isValidPassword
    },
    phoneNumber: {
      error: 'Invalid phone number, must be 10 digits',
      validator: isValidPhoneNumber
    }
  }

  const requestSignUpUser = async () => {
    const captchaToken = await recaptchaRef.current.executeAsync()

    if (isFormValid() && captchaToken) {
      const fullName = getNameParts(field.name)
      const trace = (await axios.get('https://api.ipify.org/?format=json')).data
      const payload = postAccountPayload({
        firstName: fullName.firstName,
        lastName: fullName.lastName,
        email: field.email,
        password: field.password,
        phoneNumber: getPhoneNumberDigits(field.phoneNumber),
        ipAddress: trace.ip,
        captchaToken
      })

      setLoading(true)

      try {
        await API.post('TALK_IT_API', `/account`, { body: payload })
        setLoading(false)
        setSignedUp(true)
        log(events.USER_REGISTERED)
      } catch (error) {
        setLoading(false)
        setSignedUp(false)
        setSignedUpError(true)
      }
    }
  }

  if (signedUp) {
    return <Redirect to={{ pathname: '/thankyou', state: { email: field.email, name: field.name } }} />
  }

  return (
    <SignUpContainer>
      <LogoWrapper>
        <Logo alt="Mobile Assistant's Talk It Online Logo"/>
      </LogoWrapper>
      <H1 center>Sign up</H1>
      <InputContainer>
        <Input
          error={fieldError.name}
          label="Full Name"
          name="name"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="Alex Advisor"
          type="text"
          value={field.name}
          autoFocus />
        <Input
          error={fieldError.email}
          label="Work Email"
          name="email"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="name@company.com"
          type="email"
          value={field.email} />
        <Input
          error={fieldError.password}
          label="Password"
          name="password"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="Something Secure (7+ Characters)"
          type="password"
          value={field.password} />
        <Input
          error={fieldError.phoneNumber}
          guide={false}
          label="Phone Number"
          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          name="phoneNumber"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="(555) 555-5555"
          type="tel"
          value={field.phoneNumber}
        />
        {signedUpError && <Error>We had trouble creating your account. Send us an email at <AnchorLink href="mailto:support@mobileassistant.us">support@mobileassistant.us</AnchorLink> and we'll help you out!</Error>}
        <Button disabled={!isFormValid()} onClick={requestSignUpUser}>
          {!loading && <span>Start My Free Trial</span>}
          {loading && <LoadingSpinner />}
        </Button>
        <LinkButton id="alreadyhaveaccount" to={{ pathname: '/signin', state: { email: field.email } }} text>Already have an account?</LinkButton>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={env.RECAPTCHA_API_KEY}
          size="invisible"
        />
      </InputContainer>
    </SignUpContainer>
  )
}

export default SignUp
