import {
  COLORS,
  ColorPickerWrapper,
  ContentWrapper,
  DeleteIconWrapper,
  DialogContent,
  DialogText,
  EditContainer,
  IconWrapper,
  NewQuestion,
  PaletteWrapper,
  QuestionContainer,
  QuestionInput,
  TemplateWrapper,
  TitleInput,
  TitleWrapper,
  ValidationMessage
} from './index.styles'
import React, { useState } from 'react'
import { mdiAlert, mdiCloseThick, mdiDotsGrid, mdiPalette } from '@mdi/js'

import { API } from 'aws-amplify'
import Icon from '@mdi/react'
import Modal from 'shared/components/Modal'
import { ReactSortable } from 'react-sortablejs'
import { colors } from 'shared/colors'
import { getCognitoId } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import LoadingSpinner from 'shared/components/LoadingSpinner'

function EditTemplate ({ template, onBack, onSave, ...props }) {
  const { currentUser } = useAuthContext()
  const { closeModal, openModal } = useModalContext()
  const { sendErrorNotification } = useNotificationContext()
  const cognitoId = getCognitoId(currentUser)
  const [title, setTitle] = useState(template.title)
  const [loading, setLoading] = useState(false)
  const [currentColor, setCurrentColor] = useState(template.color)
  const [questionArray, setQuestionArray] = useState(template.questions.map((x) => { return { name: x } })) // eslint-disable-next-line
  const [newQuestion, setNewQuestion] = useState(null)
  const [hasChanged, setHasChanged] = useState(template.isAddTemplate)
  const [validationText, setValidationText] = useState('')

  const updateTitle = ({ target }) => {
    setTitle(target.value)
  }

  const updateQuestion = (evt) => {
    if (evt.currentTarget.value.length < 100) {
      const index = parseInt(evt.currentTarget.dataset.index)
      const newValue = evt.currentTarget.value
      const newArr = [...questionArray]
      // eslint-disable-next-line security/detect-object-injection
      newArr[index] = { ...newArr[index], name: newValue }
      setQuestionArray(newArr)
      setHasChanged(true)
    }
  }

  const maybeCreateNextQuestion = (event) => {
    if (event.key === 'Enter' && event.currentTarget.value) {
      addToQuestionArray()
    }
  }

  const deleteTemplate = async () => {
    closeModal()
    try {
      await API.put('TALK_IT_API_ADMIN', `/assistant_template/${template.id}?cognito_id=${cognitoId}`, {
        body: {
          is_deleted: true
        }
      })
      setHasChanged(false)
      onSave()
    } catch (error) {
      if (error.request && error.request.status === 400) {
        sendErrorNotification(error.request.errors)
      } else {
        sendErrorNotification(error)
      }
    }
  }

  const addToQuestionArray = () => {
    const newIndex = questionArray.length
    setNewQuestion(newIndex)
    setQuestionArray(questions => [...questions, { name: '' }])
    setHasChanged(true)
  }

  const removeFromQuestionArray = (index) => {
    const newArr = [...questionArray]
    newArr.splice(index, 1)
    setQuestionArray(newArr)
    setHasChanged(true)
  }

  const changeColor = (color) => {
    setCurrentColor(color)
    setHasChanged(true)
  }

  const showAlertModal = () => {
    const modalDialogue = {
      primary: {
        onClick: discardChanges,
        children: 'Continue'
      },
      secondary: {
        onClick: closeDialog
      }
    }
    openModal(<Modal dialogue={modalDialogue} >
      <DialogContent>
        <Icon color={colors.RED} path={mdiAlert} size={5} />
        <DialogText>If you navigate from this page your changes will be lost. Are you sure you want to continue without saving?</DialogText>
      </DialogContent>
    </Modal>)
  }

  const showDeleteModal = () => {
    const modalDialogue = {
      primary: {
        onClick: deleteTemplate,
        children: 'Continue'
      },
      secondary: {
        onClick: closeDialog
      }
    }
    openModal(<Modal dialogue={modalDialogue} >
      <DialogContent>
        <Icon color={colors.RED} path={mdiAlert} size={5} />
        <DialogText>You are about to delete this template. Are you sure you want to continue?</DialogText>
      </DialogContent>
    </Modal>)
  }

  const saveNewTemplate = async () => {
    try {
      setLoading(true)
      await API.post('TALK_IT_API_ADMIN', `/assistant_template?cognito_id=${cognitoId}`, {
        body: {
          title: title.trim(),
          color: currentColor,
          questions: questionArray.filter(x => x.name.trim() !== '').map(x => x.name.trim())
        }
      })
      setHasChanged(false)
      onSave()
    } catch (error) {
      if (error.request && error.request.status === 400) {
        sendErrorNotification(error.request.errors)
      } else {
        sendErrorNotification(error)
      }
    } finally {
      setLoading(false)
    }
  }

  const updateTemplate = async () => {
    try {
      setLoading(true)
      await API.put('TALK_IT_API_ADMIN', `/assistant_template/${template.id}?cognito_id=${cognitoId}`, {
        body: {
          title: title.trim(),
          color: currentColor,
          questions: questionArray.filter(x => x.name.trim() !== '').map(x => x.name.trim())
        }
      })
      setHasChanged(false)
      onSave()
    } catch (error) {
      if (error.request && error.request.status === 400) {
        sendErrorNotification(error.request.errors)
      } else {
        sendErrorNotification(error)
      }
    } finally {
      setLoading(false)
    }
  }

  const isFormValid = () => {
    if (!title?.trim() || title.trim() === 'New Template') {
      setValidationText('A template title is required.')
      return false
    }
    if (!questionArray.some(x => x.name.trim())) {
      setValidationText('At least one question is required.')
      return false
    }
    return true
  }

  const saveTemplate = () => {
    if (!isFormValid()) return
    if (template.isAddTemplate) {
      saveNewTemplate()
    } else {
      updateTemplate()
    }
  }

  const goBack = () => {
    if (hasChanged) {
      showAlertModal()
      return
    }
    onBack()
  }

  const closeDialog = () => {
    closeModal()
  }

  const discardChanges = () => {
    closeModal()
    onBack()
  }

  const getModalDialogue = () => {
    const modalDialogue = {
      primary: {
        onClick: saveTemplate,
        children: loading ? <LoadingSpinner /> : 'Save and Close'
      }
    }

    if (!template.isAddTemplate) {
      modalDialogue.secondary = {
        onClick: showDeleteModal,
        children: 'Delete...'
      }
    }

    return modalDialogue
  }

  const modalDialogue = getModalDialogue()
  const displayTitle = title === 'New Template' ? null : title

  return (
    <Modal dialogue={modalDialogue} onClose={goBack} title="Edit Template">
      <EditContainer>
        <ContentWrapper>
          <TemplateWrapper>
            <div>Title</div>
            <TitleWrapper color={currentColor}>
              <TitleInput onChange={updateTitle} placeholder="New Template" value={displayTitle} autoFocus />
            </TitleWrapper>
            <div>Color</div>
            <ColorPickerWrapper>
              {COLORS.map((x, key) =>
                <PaletteWrapper color={x || colors.BLUE} key={key} onClick={() => changeColor(x)}>
                  <Icon color={colors.WHITE} path={x === currentColor ? mdiPalette : ''} size={2}/>
                </PaletteWrapper>)}
            </ColorPickerWrapper>
            <div>Questions</div>
            {validationText && <ValidationMessage>{validationText}</ValidationMessage>}
            <ReactSortable animation={300} ghostClass="ghost-item-placeholder" handle='.dragHandel' list={questionArray} setList={setQuestionArray}>
              {questionArray.map((item, index) => (
                <QuestionContainer
                  color={currentColor}
                  key={index}
                >
                  <IconWrapper className="dragHandel" color={currentColor}>
                    <Icon color={colors.WHITE} path={mdiDotsGrid} size={1} />
                  </IconWrapper>
                  <QuestionInput autoFocus={index === newQuestion} data-index={index} key={index} name={'question_' + index} onChange={updateQuestion} onKeyDown={maybeCreateNextQuestion} value={item.name ?? ''} />
                  <DeleteIconWrapper>
                    <Icon color={colors.RED} onClick={() => removeFromQuestionArray(index)} path={mdiCloseThick} size={0.75} title="Delete Question" />
                  </DeleteIconWrapper>
                </QuestionContainer>
              )
              )}
            </ReactSortable>
            <QuestionContainer color={currentColor || colors.BLUE}>
              <NewQuestion onClick={addToQuestionArray}>Add a question...</NewQuestion>
            </QuestionContainer>
          </TemplateWrapper>
        </ContentWrapper>
      </EditContainer>
    </Modal>
  )
}

export default EditTemplate
