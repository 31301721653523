import styled from 'styled-components'
import { colors } from 'shared/colors'

export const Container = styled.div`
    width: 100%;
    color: ${colors.BLACK};
    display: flex;
    flex-flow: column;
    padding: .5rem;
    border-radius: .9rem;
    transition: all .25s ease;

    span {
        margin-left: calc(24px + .75rem);
        position: relative;
        color: ${colors.GREY_700};
    }

    :hover {
        cursor: pointer;
        background: ${colors.GREY_100};
    }

    :active {
        opacity: .6;
    }
`

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const RadioIcon = styled.div`
    padding: .25rem;
    margin-right: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`
