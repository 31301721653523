import { colors } from 'shared/colors'
import styled from 'styled-components'

export const COLORS = [
  colors.BLUE,
  colors.ORANGE,
  colors.GREEN,
  colors.PINK,
  colors.TEAL,
  colors.PURPLE,
  colors.RED,
  colors.GREY_900
]

export const EditContainer = styled.div`
    margin-top: 1rem;
    width: 100%;
`

export const TemplateWrapper = styled.div`
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const TitleInput = styled.input`
    font-size: 2rem;
    border: none;

    &:focus {
        border: none;
        outline: none;
    }
`

export const IconWrapper = styled.div`
    width: 7%;
    background-color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
    cursor: -webkit-grabbing;
`

export const PaletteWrapper = styled.div`
    background-color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: .5rem solid ${props => props.color};
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.25rem;
`

export const ColorPickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
`

export const QuestionContainer = styled.div`
    display: flex;
    border: 2px solid ${props => props.color};
    border-radius: 5px;
    margin-top: 20px;
    background-color: #ffffff;

    input {
        padding: 10px;
    }

    &.ghost-item-placeholder {
        opacity: 0;
    }
`

export const QuestionInput = styled.input`
    width: 100%;
    border: none;
    font-size: 1rem;

    &:focus {
        border: none;
        outline: none;
    }
`

export const NewQuestion = styled.div`
    width: 100%;
    border: none;
    height: 40px;
    padding-top: 8px;
    padding-left: 5px;
    color: ${colors.GREY_300};
`

export const TitleWrapper = styled.div`
    display: flex;
    border: none;
    border-bottom: 2px solid ${props => props.color};
    border-radius: 0;
    margin-bottom: 2rem;
`

export const DeleteIconWrapper = styled.div`
    > SVG {
        margin-top: 0.7rem;
        margin-right: 0.5rem;

        cursor: pointer;
        &:hover {
            transform: scale(1.1); 
        }
    }
`

export const DialogContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
`
export const DialogText = styled.div`
    padding-left: 10px;
`

export const ValidationMessage = styled.div`
    color: ${colors.RED}
`
