import styled, { css } from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const StyledNotes = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
`

export const StyledNotesList = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: auto;

    ${media.desktop} {
        max-width: 300px;
    }

    ${media.tablet} {
        height: calc(100% - 4rem);
    }
`

export const NoNoteMessage = styled.div`
    padding: 2rem 2rem;
    color: ${colors.GREY_700};

    h3 {
        line-height: 1.5rem;
    }
`

export const AudioBar = styled.audio`
    outline: none;
    position: sticky;
    bottom: 0;
    width: 100%;
    min-height: 3rem;

    ${media.print} {
        display: none;
    }
`

export const FloatingActions = styled.div`
    display: none;

    ${props => props.opened && css`
        z-index: 200;
        position: absolute;
        left: 1rem;
        top: 1rem;
        display: block;

        ${media.desktop} {
            display: none;
        }
    `}

    ${media.print} {
        display: none;
    }
`

export const NoteList = styled.ul`
    list-style: none;
    padding: 0 0 5rem 0;
    margin: 0;
    width: 100%;

    li:last-of-type {
        div {
            &:after {
                content: none;
            }
        }
    }
`

export const NoteListItem = styled.li`
    width: 100%;
`

export const FloatingUploadAction = styled.div`
    z-index: 200;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    display: block;

    ${media.tablet} {
        bottom: 5rem;
        position: fixed;
    }

    ${media.print} {
        display: none;
    }
`

export const NoteDetailContainer = styled.div`
    flex: 1;
    border-left: 2px solid ${colors.GREY_200};
    padding: 1rem;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    ${props => props.opened && css`
        position: absolute;
        width: 100%;
        background-color: ${colors.WHITE};
        left: 0;
        top: 0;
        z-index: 800;
        border-left: none;

        ${media.desktop} {
            border-left: 2px solid ${colors.GREY_200};
            position: relative;
        }
    `}

    ${props => !props.opened && css`
        display: none;

        ${media.desktop} {
            display: flex;
        }
    `}

    p {
        margin-top: 0;
    }

    ${media.print} {
        overflow: visible;
    }
`

export const EmptyStateGraphic = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 285px;
    margin: 0 auto;
    
    svg {
        margin: 0 auto 2rem;
        width: 75%;
        height: auto;
    }

    h3 {
        font-weight: normal;
    }
`
