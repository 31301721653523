import { Area, DetailsWrapper, Divider, Header, Section, TemplateList } from './index.styles'
import React, { useEffect, useState } from 'react'

import { API } from 'aws-amplify'
import EditTemplate from '../shared/components/EditTemplate'
import { H1 } from 'shared/components/Text'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import TemplateListItem from '../TemplateListItem'
import ViewTemplate from '../shared/components/ViewTemplate'
import { colors } from 'shared/colors'
import { getTalkItUser } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'

function AssistantOverview () {
  const Views = {
    LIST: 1,
    VIEW_TEMPLATE: 2,
    EDIT_TEMPLATE: 3,
    LOADING: 4
  }
  const { sendErrorNotification } = useNotificationContext()
  const { currentUser } = useAuthContext()
  const { cognitoId } = getTalkItUser(currentUser)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [view, setView] = useState(Views.LOADING)
  const [isHistoryBlocked, setIsHistoryBlocked] = useState(false)

  const [templates, setTemplates] = useState([])

  const loadTemplates = async () => {
    if (!cognitoId) {
      return []
    }

    try {
      const response = await API.get('TALK_IT_API', `/assistant_template?cognito_id=${cognitoId}`)
      setTemplates(response.data)
      setView(Views.LIST)
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    loadTemplates()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createTemplate = {
    isAddTemplate: true,
    user_id: cognitoId,
    color: colors.BLUE,
    title: 'New Template',
    questions: [
    ]
  }

  const blockHistory = (callback) => {
    if (isHistoryBlocked) return
    window.history.pushState(null, '', window.location.href)
    setIsHistoryBlocked(true)
    window.onpopstate = (e) => {
      e.preventDefault()
      window.location.reload()
    }
  }

  const resetHistory = () => {
    window.onpopstate = undefined
    setIsHistoryBlocked(false)
    window.history.back()
  }

  const templateSaved = async () => {
    setView(Views.LOADING)
    await loadTemplates()
    setSelectedTemplate({})
    setView(Views.LIST)
  }

  const openTemplate = (template) => {
    blockHistory()
    setSelectedTemplate(template)
    setView(Views.VIEW_TEMPLATE)
  }

  const editTemplate = (template) => {
    blockHistory()
    setSelectedTemplate(template)
    setView(Views.EDIT_TEMPLATE)
  }

  const closeTemplate = () => {
    resetHistory()
    setSelectedTemplate({})
    setView(Views.LIST)
  }

  const updateTemplateGroup = async (template, groupId) => {
    try {
      await API.put('TALK_IT_API_ADMIN', `/assistant_template/${template.id}?cognito_id=${cognitoId}`, {
        body: {
          group_id: groupId
        }
      })
      await templateSaved()
    } catch (error) {
      if (error.request && error.request.status === 400) {
        sendErrorNotification(error.request.errors)
      } else {
        sendErrorNotification(error)
      }
    }
  }

  const menuAction = (template, menuItem) => {
    switch (menuItem) {
    case 'Edit':
      editTemplate(template)
      break
    case 'View':
      openTemplate(template)
      break
    case 'ShareTeam':
      updateTemplateGroup(template, currentUser.talkItAttributes.user.group.team_group_id)
      break
    case 'ShareOrg':
      updateTemplateGroup(template, currentUser.talkItAttributes.user.group.org_group_id)
      break
    case 'ShareEveryone':
      updateTemplateGroup(template, 1)
      break
    case 'StopShare':
      updateTemplateGroup(template, null)
      break
    default:
    }
  }

  return (
    <Area>
      <Header>
        <H1>Assistant Template Library</H1>
      </Header>
      <Divider/>
      <Section>
        {view === Views.LOADING && <LoadingSpinner />}
        {view !== Views.LOADING && (
          <TemplateList>
            {templates?.length > 0 && templates.map((x) =>
              <TemplateListItem
                color={colors.WHITE}
                key={x.id}
                onClick={() => openTemplate(x)}
                onMenu={menuAction}
                template={x}
                user={currentUser}
              />
            )}
            <TemplateListItem
              color={colors.GREY_900}
              onClick={() => editTemplate(createTemplate)}
              template={createTemplate}
              user={currentUser}
            />
          </TemplateList>
        )}
        {view === Views.VIEW_TEMPLATE && (
          <DetailsWrapper>
            <ViewTemplate onBack={closeTemplate} onEdit={editTemplate} template={selectedTemplate} user={currentUser} />
          </DetailsWrapper>
        )}
        {view === Views.EDIT_TEMPLATE && (
          <DetailsWrapper>
            <EditTemplate onBack={closeTemplate} onSave={templateSaved} template={selectedTemplate} />
          </DetailsWrapper>
        )}
      </Section>
    </Area>
  )
}

export default AssistantOverview
