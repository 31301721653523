import React from 'react'
import styled, { css } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { colors } from 'shared/colors'

const sharedButtonStyles = css`
  align-items: center;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  outline: none;
  text-align: center;
  padding: ${props => (props.small ? '.35rem' : '.75rem')};
  background-color: ${props => (props.secondary ? colors.BLACK : colors.BLUE)};
  color: ${colors.WHITE};
  border-radius: 40px;
  box-shadow: ${props => (props.fab ? `0 .2rem .875rem ${colors.BLACK_A20}` : `0 .2rem .875rem ${colors.TRANSPARENT}`)};
  text-decoration: none;
  display: flex;
  WHITE-space: nowrap;
  justify-content: center;
  transition: all .25s ease;
  box-size: border-box;
  min-height: ${props => (props.small ? '1.875rem' : '3rem')};
  height: ${props => (props.small ? '2rem' : '3rem')};
  min-width: 3rem;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  :hover {
    cursor: pointer;
    color: ${colors.WHITE};
    transform: ${props => (props.fab ? 'translateY(-2px)' : 'translateY(-2px)')};
    box-shadow: ${props => (props.fab ? `0 .25rem .95rem ${colors.BLACK_A20};` : `0 .2rem .875rem ${colors.BLACK_A20};`)};
  }

  :active {
    transform: ${props => (props.fab ? 'translateY(2px)' : 'translateY(2px)')};
    box-shadow: 0 0px 0px ${colors.TRANSPARENT};
    opacity: ${props => (props.fab ? '1' : '.6')}
  }

  :visited {
      color: ${colors.WHITE};
    }

  ${props => props.inverse && css`
    color: ${colors.BLACK};
    background: ${colors.GREY_200};

    :hover {
      color: ${colors.BLACK};
      transform: translateY(-2px);
    }

    :active {
      transform: translateY(0);
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.text && css`
    color: ${colors.BLACK};
    background: ${colors.TRANSPARENT};

    :hover {
      color: ${colors.BLACK};
      background: ${props => props.disabled ? 'none' : colors.GREY_100};
      transform: translateY(0px);
      box-shadow: 0 0 0 ${colors.TRANSPARENT};
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.inline && css`
    color: ${colors.BLACK};
    background: ${colors.TRANSPARENT};
    padding: 0;
    position: relative;

    span {
      position: relative;
      z-index: 10;
    }

    :after {
      content: "";
      display: block;
      width: calc(100% + 1.5rem);
      position: absolute;
      top: 0.25rem;
      left: -0.75rem;
      height: calc(100% - 0.5rem);
      z-index: 0;
      border-radius: 50px;
      transition: all 0.2s ease;
    }

    :hover {
      color: ${colors.BLACK};
      background: ${colors.TRANSPARENT};
      transform: translateY(0px);
      box-shadow: 0 0 0 ${colors.TRANSPARENT};

      :after {
          background: ${colors.GREY_100};
      }
    }

    :visited {
      color: ${colors.BLACK};
    }
  `}

  ${props => props.banner && css`
    color: ${colors.BLUE};
    background: ${colors.TRANSPARENT};
    padding: 0;

    :hover {
      color: ${colors.BLUE};
      background: ${props => props.disabled ? 'none' : colors.GREY_100};
      transform: translateY(0px);
      box-shadow: 0 0 0 ${colors.TRANSPARENT};
    }

    :visited {
      color: ${colors.BLUE};
    }
  `}

  ${props => props.disabled && css`
    opacity: .3 !important;
        
    :hover {
      cursor: default;
      transform: translateY(0px);
      box-shadow: ${props => (props.fab ? `0 .2rem .875rem ${colors.BLACK_A20}` : `0 .2rem .875rem ${colors.TRANSPARENT}`)};
    }

    :active {
      opacity: .3 !important;
      transform: translateY(0px);
      box-shadow: ${props => (props.fab ? `0 .2rem .875rem ${colors.BLACK_A20}` : `0 .2rem .875rem ${colors.TRANSPARENT}`)};
    }
  `}
`

export const StyledButton = styled.a`
  ${props => sharedButtonStyles}
`

export const StyledLinkButton = styled(({ text, inverse, inline, small, disabled, secondary, isActive, banner, ...restProps }) => <Link {...restProps} />)`
  ${props => sharedButtonStyles}
`

export const StyledNavLinkButton = styled(({ text, inverse, inline, small, disabled, secondary, isActive, banner, ...restProps }) => <NavLink activeClassName={'active'} {...restProps} />)`
  ${props => sharedButtonStyles}
`
