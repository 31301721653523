import envDev from './dev'
import envLocal from './local'
import envTest from './test'
import envProd from './prod'

const getEnvironmentVariables = () => {
  if (process.env.REACT_APP_ENV === undefined) { return envDev }
  if (process.env.REACT_APP_ENV === 'dev') { return envDev }
  if (process.env.REACT_APP_ENV === 'local') { return envLocal }
  if (process.env.REACT_APP_ENV === 'test') { return envTest }
  if (process.env.REACT_APP_ENV === 'prod') { return envProd }
}

export const isNotRunningLocally = () =>
  (process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV !== 'local')

export default getEnvironmentVariables()
