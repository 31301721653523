/* eslint-disable no-tabs */
import styled from 'styled-components'
import { colors } from 'shared/colors'

export const SignUpContainer = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  max-width: 450px;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
  color: ${colors.BLACK};
`

export const Heading = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 2.25rem;
  text-align: center;
  margin-bottom: 0.75rem;
  line-height: 1.25;
  margin-top: 0;
  padding-top: 0;
`

export const Text = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  text-align: center;
  font-weight: normal;
  margin-top: 0;
  color: ${colors.BLACK};
`

export const InlineCallOut = styled.span`
  color: ${colors.BLUE};
`

export const Img = styled.img`
  display: flex;
  justify-self: center;
  align-self: center;
  width: 85%;
  height: auto;
`
