import React from 'react'
import { HorizontalScroll, ListContainer, ListHeader, ColumnHeader, QTYHeader, ListBody, HeaderRow } from './index.styles'

const InvoiceList = ({ children, showQuantity }) => {
  return (
    <HorizontalScroll>
      <ListContainer>
        <ListHeader>
          <HeaderRow>
            <ColumnHeader>Status</ColumnHeader>
            <ColumnHeader>Date</ColumnHeader>
            {showQuantity ? (<QTYHeader>QTY</QTYHeader>) : (null)}
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>View Invoice</ColumnHeader>
          </HeaderRow>
        </ListHeader>
        <ListBody>
          {children}
        </ListBody>
      </ListContainer>
    </HorizontalScroll>

  )
}

export default InvoiceList
